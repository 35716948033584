<template>
  <!--  轮班视图-->
  <div class="shift-plan">
    <div v-loading="isPlanLoading" v-show="true" class="shift-table">
      <div class="header-option">
        <div class="tab-view">
          <!--          <div :class="activeName===0?'tab-view-item tab-view-item-active':'tab-view-item'" @click="handleTabClick(0)">轮班</div>-->
          <div
            :class="
              activeName === 1
                ? 'tab-view-item tab-view-item-active'
                : 'tab-view-item'
            "
            @click="handleTabClick(1)"
          >
            <!--            资源-->
            {{ $t("label.service.resources") }}
          </div>
        </div>
        <!--      右上角切换按钮-->
        <div class="btn-box" v-if="true">
          <el-button :disabled="isNoArea" size="small" @click="gotoWeek(-1)">
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <el-button
            :disabled="isNoArea"
            size="small"
            @click="gotoWeek(1)"
            style="margin-left: 0"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
          <el-button :disabled="isNoArea" size="small" @click="gotoWeek(0)">
            {{ $t("today") }}
          </el-button>
        </div>
      </div>
      <div v-show="isNoArea" class="no-area">
        {{ failedInfo }}
      </div>
      <!--      轮班-->
      <div v-show="activeName === 0">
        <!--星期视图表头-->
        <div class="week-container">
          <div class="week-day"></div>
          <div
            v-for="(item, index) in weekBox"
            :key="index"
            :class="
              item.day.num !== todayDate
                ? 'week-day'
                : 'week-day week-day-active'
            "
          >
            {{ item.day.week }}
          </div>
        </div>
      </div>
      <!--      资源-->
      <div v-show="activeName === 1">
        <!--星期视图表头-->
        <div class="week-container">
          <div class="week-day"></div>
          <div
            v-for="(item, index) in weekBox"
            :key="index"
            :class="
              item.day.num !== todayDate
                ? 'week-day'
                : 'week-day week-day-active'
            "
          >
            {{ item.day.week }}
          </div>
        </div>

        <!--          下方数据展示-->
        <div class="week-event" id="wrap">
          <div
            class="week-event-item"
            v-for="(item, index) in areaList"
            :key="index"
          >
            <!--              按区域区分-->
            <div class="week-event-area" @click="tapAreaStatus(item, index)">
              <i class="el-icon-arrow-down" v-show="item.openStatus"></i>
              <i class="el-icon-arrow-right" v-show="!item.openStatus"></i>
              {{ item.name }}
            </div>
            <div v-for="(itemBase, indexBase) in areaData" :key="indexBase">
              <template v-for="(itemA, indexA) in itemBase.areaData">
                <div
                  v-if="item.openStatus && item.id === itemBase.area"
                  class="week-event-table"
                  :key="indexA"
                >
                  <div class="week-item">
                    <!--左侧展示的每个人-->
                    <div
                      class="week-item-day-left"
                      v-show="
                        itemA.title === '未分配' ||
                        itemBase.area !== 'noAllot' ||
                        itemA.showEvents.length > 0
                      "
                    >
                      <!--                    未分配  不用处理多语言-->
                      <div
                        class="item-day-left-unallot"
                        v-if="itemA.title === '未分配'"
                      >
                        <!--                      {{ itemA.title }}-->
                        <!--                      未分配-->
                        {{ $t("vue_label_pool_unassigned") }}
                      </div>
                      <!--                    已分配给人-->
                      <div
                        class="item-day-left-alloted"
                        v-else
                        @click="jumpDetail(itemA.titleId)"
                      >
                        <svg
                          aria-hidden="true"
                          class="item-day-left-alloted-icon"
                        >
                          <use href="#icon-guanzhuzhe"></use>
                        </svg>
                        {{ itemA.title }}
                      </div>
                    </div>
                    <!--                  右侧的日历格子-->
                    <div
                      v-show="
                        itemA.title === '未分配' ||
                        itemBase.area !== 'noAllot' ||
                        itemA.showEvents.length > 0
                      "
                      class="week-item-day"
                      v-for="(itemBox, indexBox) in weekBox"
                      :key="indexBox"
                      @click="handleGridDate(item, itemA, itemBox)"
                    >
                      <div
                        v-for="(itemB, indexB) in itemA.showEvents"
                        :key="indexB"
                        @click.stop="handleClickEvent"
                      >
                        <div
                          v-if="itemBox.day.num === itemB.flag"
                          :class="[
                            'week-item-order',
                            indexA === 0 ? 'week-item-order-bg' : '',
                            itemB.statusCode === '新建'
                              ? 'week-item-order-ba'
                              : '',
                            itemB.statusCode === '已确认'
                              ? 'week-item-order-bb'
                              : '',
                            itemB.statusCode === '已提交'
                              ? 'week-item-order-bc'
                              : '',
                          ]"
                        >
                          <Popover-cpn
                            :recordObj="itemB"
                            :content="itemB.name"
                            @jumpDetail="jumpDetail(itemB.id)"
                          />
                          <div class="week-item-order-t">
                            {{ itemB.starttime + "-" + itemB.endtime }}
                          </div>
                          <div class="week-item-order-b">
                            {{ itemB.status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-edit-obj
      ref="createEditObj"
      prefix="s49"
      objectApi="Shift"
      realObjId="Shiftstdobj02021"
      :object-name="$t('label.service.shift')"
      type="active"
      @save="save"
    ></create-edit-obj>
  </div>
</template>

<script>
import createEditObj from "./create-edit-obj.vue";
import PopoverCpn from "./../../homePage/components/PopoverCpn";
import * as shiftApi from "./api.js";

export default {
  name: "shift-plan",
  props: {
    viewId: {
      type: String,
    },
  },
  components: {
    PopoverCpn,
    createEditObj,
  },
  data() {
    return {
      // 加载状态
      isPlanLoading: true,
      // 当前菜单
      activeName: 1,
      // 今天
      todayDate: "",
      // 当前日期
      currentFirstDate: null,
      // 星期日期展示
      weekBox: [
        { id: 0, day: {} },
        { id: 1, day: {} },
        { id: 2, day: {} },
        { id: 3, day: {} },
        { id: 4, day: {} },
        { id: 5, day: {} },
        { id: 6, day: {} },
      ],
      // 区域list
      areaList: [],
      areaListObj: {},
      idList: "",
      // 区域下的数据
      areaData: {},
      startTime: "",
      endTime: "",
      searchWord: "",
      failedInfo: "", // 后台返回报错信息
      isNoArea: false, // 没有区域
    };
  },
  watch: {
    viewId(newVal) {
      if (
        newVal !== "0" &&
        newVal !== "1" &&
        newVal !== "2" &&
        newVal !== "3" &&
        newVal !== undefined
      ) {
        this.renderPage();
      }
    },
  },
  methods: {
    renderPage() {
      this.getAreas();
    },
    save() {
      this.getShifts();
    },
    //跳转到记录详情
    jumpDetail(item) {
      this.$router.push({
        path: `/commonObjects/detail/${item}/DETAIL`,
      });
    },
    // 获取两个日期之间的每一天时间
    getDatesFromTwo(start, end) {
      let dateList = [];
      let startTime = new Date(start);
      let endTime = new Date(end);

      if (
        endTime.getHours() === 0 &&
        endTime.getMinutes() === 0 &&
        endTime.getSeconds() === 0
      ) {
        endTime = new Date(endTime - 1000);
      }

      while (endTime.getTime() - startTime.getTime() >= 0) {
        let year = startTime.getFullYear();
        let month =
          startTime.getMonth() + 1 < 10
            ? "0" + (startTime.getMonth() + 1)
            : startTime.getMonth() + 1;
        let day =
          startTime.getDate().toString().length === 1
            ? "0" + startTime.getDate()
            : startTime.getDate();
        dateList.push(year + "-" + month + "-" + day);
        startTime.setDate(startTime.getDate() + 1);
      }
      return dateList;
    },
    handleClickEvent() {},
    // 点击格子
    handleGridDate(area, item, week) {
      let op = {
        area: {},
        resouce: {},
        date: {},
      };

      if (area.id !== "noAllot") {
        op.area = {
          id: area.id,
          name: area.name,
        };
      }
      // 判断标识，无需多语言
      if (item.title !== "未分配") {
        op.resouce = {
          title: item.title,
          titleId: item.titleId,
        };
      }
      op.date = {
        startTime: week.day.num + " 08:00:00",
        endTime: week.day.num + " 18:00:00",
      };

      this.$refs.createEditObj.handleShift(op);
    },
    // 切换视图
    handleTabClick(tab) {
      this.activeName = tab;
    },
    // 切换时间
    gotoWeek(flag) {
      if (flag === -1) {
        this.handleDate(this.addDate(this.currentFirstDate, -7));
      } else if (flag === 1) {
        this.handleDate(this.addDate(this.currentFirstDate, 7));
      } else if (flag === 0) {
        let d = new Date();
        this.handleDate(this.addDate(d, -1));
      }
    },
    // 格式化时间
    formatDate(date) {
      let month = date.getMonth() + 1 + "/";
      let day = date.getDate() + " ";
      let week = [
        this.$i18n.t("label.appointment.wizard.subtitle3-7"),
        this.$i18n.t("label.appointment.wizard.subtitle3-1"),
        this.$i18n.t("label.appointment.wizard.subtitle3-2"),
        this.$i18n.t("label.appointment.wizard.subtitle3-3"),
        this.$i18n.t("label.appointment.wizard.subtitle3-4"),
        this.$i18n.t("label.appointment.wizard.subtitle3-5"),
        this.$i18n.t("label.appointment.wizard.subtitle3-6"),
      ][date.getDay()];

      let yy = date.getFullYear();
      let mm =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      let obj = {
        week: month + day + " " + week,
        num: yy + "-" + mm + "-" + dd,
      };

      return obj;
    },
    // 处理时间
    addDate(date, n) {
      date.setDate(date.getDate() + n);
      return date;
    },
    // 处理时间
    handleDate(date) {
      let week = date.getDay() - 1;
      date = this.addDate(date, week * -1);
      this.currentFirstDate = new Date(date);
      this.weekBox.forEach((item, index) => {
        item.day = this.formatDate(index === 0 ? date : this.addDate(date, 1));
      });
      this.startTime = this.weekBox[0].day.num + " 00:00:00";
      this.endTime = this.weekBox[6].day.num + " 23:59:59";
      this.getShifts();
    },
    // 获取当天日期
    getToday() {
      let dd = new Date();
      let year = dd.getFullYear();
      let month =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      let day = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();

      this.todayDate = year + "-" + month + "-" + day;
    },
    // 切换区域状态
    tapAreaStatus(item, index) {
      this.areaList[index].openStatus = !item.openStatus;
      this.$set(this.areaList, index, this.areaList[index]);
    },
    getShifts() {
      this.isPlanLoading = true;
      let option = {
        //         obj	是	string	对象前缀
        obj: "s49",
        //         viewId	是	string	视图Id
        viewId: this.viewId,
        //         pageNum	是	string	页数
        //         pageNum: 1,
        //         pageSize	是	string	每页数量
        //         pageSize: 10000,
        //         sortField	是	string	排序字段apiname
        sortField: "",
        //         sortDir	是	string	排序类型(desc[降序]/asc[升序])
        sortDir: "",
        //         searchKeyWord	是	string	搜索关键字；当传递exactSearchFlag=1时，见searchKeyWord格式
        searchKeyWord: this.searchWord,
        //       conditionValues	是	string	高级搜索条件
        conditionValues: "",
        //       ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
        //         ischangenum: 100000,
        //       recordnums	是	string	每页查询默认数目
        //         recordnums: 100000,
        //       tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
        tagIds: "",
        //       isAllTag	否	string	是否同时满足所有选中标签（true/false）
        //       campaignid	否	string	市场活动成员是否只查询现有成员
        //       isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
        //       myself	否	string	全局搜索是否只查询所有人是自己的记录
        //       scrollId	否	string	全文检索查询返回值，下一页查询回传
        //       type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
        //       exactSearchFlag	否	string	是否启用单字段搜索，值为1时启用
        //       serviceTerritoryIds	是	string	服务区域id，多个用逗号隔开
        serviceTerritoryIds: this.idList,
        //       pagenum1	是	string	有服务区域，服务资源类型页码
        //         pagenum1: 1,
        //         pagenum2: 1,
        //         pagenum3: 1,
        //         pagenum4: 1,
        //       pagesize1	是	string	有服务区域，服务资源类型每页数量
        //         pagesize1: 100000,
        //         pagesize2: 100000,
        //         pagesize3: 100000,
        //         pagesize4: 100000,
        //       pagenum2	是	string	有服务区域，无服务资源类型页码
        //       pagesize2	是	string	有服务区域，无服务资源类型每页数量
        //       pagenum3	是	string	有服务资源，无服务区域类型页码
        //       pagesize3	是	string	有服务资源，无服务区域类型每页数量
        //       pagenum4	是	string	无服务区域，无服务资源类型页码
        //       pagesize4	是	string	无服务区域，无服务资源类型每页数量
        //       startTime	是	string	开始时间 格式：2021-02-11 05:32:02
        startTime: this.startTime,
        //         startTime: '2021-02-01 00:00:00',
        //       endTime	是	string	结束时间 格式：2021-05-11 05:32:02
        endTime: this.endTime,
        // endTime: '2021-05-31 00:00:00',
      };
      shiftApi
        .getShiftRecord(option)
        .then((res) => {
          if (!res.result) {
            return false;
          }
          res.data.analogData.forEach((item) => {
            // label.tabpage.nothingz 判断标志后台返回为中文
            if (item.area === "无") {
              item.area = "noAllot";
            }
            item.areaData.forEach((itemA) => {
              itemA.showEvents = [];
              if (itemA.events.length > 0) {
                itemA.events.forEach((itemB) => {
                  itemB.dateArr = this.getDatesFromTwo(
                    itemB.starttime,
                    itemB.endtime
                  );
                  if (itemB.dateArr.length > 0) {
                    itemB.dateArr.forEach((itemC) => {
                      let temp = {
                        flag: itemC,
                        starttime: itemB.starttime,
                        endtime: itemB.endtime,
                        name: itemB.name,
                        status: itemB.status,
                        statusCode: itemB.statusCode,
                        id: itemB.id,
                        tabStyle: "cloudtab91_norm",
                        tab_style: "cloudtab91_norm",
                      };
                      itemA.showEvents.push(temp);
                    });
                  }
                });
              }
            });
          });
          let tempArr = res.data.analogData.reverse();
          tempArr.forEach((item) => {
            if (item.areaData.length > 0) {
              item.areaData.reverse();
            }
          });
          this.areaData = tempArr;
          this.isPlanLoading = false;
        })
    },
    // 获取全部服务区域
    getAreas() {
      shiftApi
        .getAllAreas()
        .then((res) => {
          if (res.result) {
            let tempObj = {
              id: "noAllot",
              name: this.$i18n.t("vue_label_pool_unassigned"),
            };
            this.isNoArea = false;
            let ids = "";
            // ids= ids.substring(0,ids.length - 1)
            res.data.unshift(tempObj);
            this.areaList = res.data;
            this.areaList.forEach((item) => {
              this.areaListObj[item.id] = item.name;
              item.openStatus = true;
              if (item.id !== "noAllot") {
                ids += item.id + ",";
              }
            });
            ids = ids.substring(0, ids.length - 1);
            this.idList = ids;
            // 初始化时间
            let d = new Date();
            this.currentFirstDate = d;
            this.handleDate(this.addDate(d, -1));
            this.getToday();
          }
        })
        .catch((err) => {
          this.failedInfo = err.returnInfo;
          this.isNoArea = true;
          this.isPlanLoading = false;
        });
    },
  },
  mounted() {
    if (this.viewId) {
      this.renderPage();
    }
  },
  created() {},
};
</script>

<style scoped lang="scss">
.shift-plan {
  //padding:  0 10px 10px 10px;
  float: left;
  width: 100%;
  background-color: #fff;
  position: relative;
  height: 80%;
  //禁止文本被选中
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}

.shift-table {
  width: 100%;
  position: relative;
  .no-area {
    padding: 30px 30px;
    border-right: 1px solid #dedcda;
  }
}

.header-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //position: fixed;
  //top: 230px;
  background-color: #fff;
  //width: calc(100% - 32px);
  height: 40px;
  //z-index: 101;
  border: 1px solid #dedcda;
  padding: 0 10px;
  .tab-view {
    display: flex;
    height: 40px;
    .tab-view-item {
      cursor: pointer;
      //width: 48px;
      font-size: 14px;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tab-view-item:first-child {
      margin-right: 20px;
    }
    .tab-view-item:hover {
    }
    .tab-view-item-active {
      color: #080707;
      font-weight: bold;
      border-bottom: 2px solid #006dcc;
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
}

.week-container {
  display: flex;
  justify-content: space-around;
  //width: calc(100% - 32px);
  background-color: #fff;
  //position: fixed;
  //top: 270px;
  //z-index: 101;
  .week-day,
  .week-day-active {
    width: 12.5%;
    border-bottom: 1px solid #dedcda;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 12px;
    border-right: none;
  }
  .week-day {
    //background-color: pink;
    color: #3e3e3c;
  }
  .week-day-active {
    //background-color: lightblue;
    color: #1589ee;
  }
  .week-day:last-child {
    border-right: 1px solid #dedcda;
  }
}

.week-event {
  //padding-top: 152px;
  height: 55vh;
  overflow-y: auto;
  .week-event-item {
    .week-event-area {
      position: sticky;
      top: 0px;
      background: #f3f2f2;
      //width: 100%;
      height: 32px;
      line-height: 32px;
      padding: 0 8px;
      text-align: left;
      font-size: 14px;
      color: #080707;
      border: 1px solid #dedcda;
    }
    .week-event-area:hover {
      cursor: pointer;
      background: #eef1f6;
    }

    .week-event-table {
      .week-item {
        display: flex;
        justify-content: space-around;
        width: 100%;
        background: #fff;
        .week-item-day-left {
          width: 12.5%;
          border: 1px solid #dedcda;
          display: flex;
          align-items: center;
          min-height: 50px;
          font-size: 12px;
          border-right: none;
          padding: 5px;
          box-sizing: border-box;
          .item-day-left-unallot,
          .item-day-left-alloted {
            font-size: 12px;
            line-height: 16px;
            padding-left: 10px;
          }
          .item-day-left-unallot {
            padding-left: 35px;
            font-weight: bold;
            color: #080707;
          }
          .item-day-left-alloted {
            color: #006dcc;
            text-decoration: underline;
            cursor: pointer;
            display: flex;
            align-items: center;
            .item-day-left-alloted-icon {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              border-radius: 50%;
            }
          }
        }
        .week-item-day {
          width: 12.5%;
          border: 1px solid #dedcda;
          min-height: 50px;
          font-size: 12px;
          border-right: none;
          padding: 5px 5px 10px 5px;
          box-sizing: border-box;
          .week-item-order {
            width: 100%;
            //height: 46px;
            border: 1px solid #dedcda;
            border-radius: 3px;
            margin-bottom: 10px;
            padding: 6px 10px;
            box-sizing: border-box;
            background: #ffffff;
            .week-item-order-t,
            .week-item-order-b {
              text-align: left;
              font-size: 12px;
              line-height: 18px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .week-item-order-t {
              color: #080707;
              //font-weight: bold;
              //cursor: pointer;
            }
            //.week-item-order-t:hover{ text-decoration:underline;}
            .week-item-order-b {
              color: #333333;
            }
          }
          //#fff3e4  未分配的背景色
          //#ff9e2c  状态 暂时
          //#4bca81  状态 确认
          //#1589ee  状态 已发布
          .week-item-order-bg {
            background: #fff3e4;
          }
          .week-item-order-ba {
            border-left: 4px solid #ff9e2c;
          }
          .week-item-order-bb {
            border-left: 4px solid #4bca81;
          }
          .week-item-order-bc {
            border-left: 4px solid #1589ee;
          }
        }
        .week-item-day:hover {
          //background: #eef1f6;
        }
        .week-item-day:last-child {
          border-right: 1px solid #dedcda;
        }
      }
    }
  }
}

.event-popover-header {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .event-popover-header-icon {
    height: 36px;
    width: 36px;
  }
  .event-popover-header-text {
    padding: 0 16px;
    font-size: 18px;
  }
}
.event-popover-center {
  height: 100px;
  overflow-y: auto;
  //padding: 0 2px 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  .detail-item {
    width: 50%;
    margin-bottom: 8px;
    .detail-item-k {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      margin-bottom: 2px;
      text-align: left;
    }
    .detail-item-v {
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      color: #080707;
      line-height: 14px;
      text-align: left;
    }
  }
}
.event-popover-footer {
  height: 30px;
  //background-color: #a0cfff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
