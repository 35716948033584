<template>
  <!-- 开发人员：李长兵 -->
  <div
    class="home"
    :style="{ height: !showKanbanView && !load ? '100%' : '' }"
    v-loading="load"
  >
    <div class="bg_img" v-if="!showKanbanView && !load">
      <!-- 请在看板视图设置中选择分组依据 -->
      <el-button
        type="text"
        class="bg_img_button"
        @click="opens"
        :disabled="!noClicks()"
      >
        <div>
          {{ $t("vue_label_commonobjects_view_kanban_unset") }}
        </div>
        <br />
        <div>({{ $t("c191") }})</div>
        <!-- 请在看板视图设置中选择分组依据 -->
      </el-button>
    </div>

    <div v-if="recordtypeGroup.length === 0 ? false : true">
      <el-tooltip
        class="item"
        popper-class="my-tooltip"
        effect="dark"
        :content="$t('vue_label_commonobjects_view_switch_display')"
        placement="top-start"
      >
        <!-- 您的记录按照记录类型分组，可点击对应的记录类型名称，切换展示。 -->
        <svg class="icon icon-tips" aria-hidden="true">
          <use href="#icon-tishi"></use>
        </svg>
      </el-tooltip>
    </div>
    <el-tabs
      v-if="recordtypeGroup.length === 0 ? false : true"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(tabs, index) in recordtypeGroup"
        :key="index"
        :label="`${
          tabs.recordtypeccname === null ||
          tabs.recordtypeccname === undefined ||
          tabs.recordtypeccname === ''
            ? $t('message.recordtype.nolist')
            : tabs.recordtypeccname
        }(${tabs.total})`"
        :name="tabs.recordtype"
      >
        <div class="box_main" v-loading="loadinges">
          <div class="box" v-for="(item, index) in groupData" :key="index">
            <!-- header -->
            <div
              :class="[
                index === groupData.length - 1
                  ? 'header last-header'
                  : 'header',
              ]"
            >
              <svg v-if="index == 0" class="icon" aria-hidden="true">
                <use href="#icon-kanban-step-1"></use>
              </svg>
              <svg
                v-else-if="index < groupData.length - 1"
                class="icon"
                aria-hidden="true"
              >
                <use href="#icon-kanban-step-2"></use>
              </svg>
              <svg v-else class="icon" aria-hidden="true">
                <use href="#icon-kanban-step-3"></use>
              </svg>
              <div class="box_header_content">
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="item.grouplabel"
                  placement="top"
                >
                  <span class="span_tooltip"
                    >{{
                      item.grouplabel === "true"
                        ? $t("label_tabpage_yesz")
                        : item.grouplabel === "false"
                        ? $t("label.checkbox.false")
                        : item.grouplabel
                    }}
                    ({{ item.groupcounts }})</span
                  >
                </el-tooltip>
              </div>
            </div>
            <div class="box_s" :class="[index !== 0 ? 'borderLeft' : '']">
              <!-- 币种 -->
              <div
                :class="item.statisticvalue !== undefined ? 'total' : 'totals'"
              >
                <span
                  v-if="
                    statisticField !== undefined &&
                    statisticField.fieldtype === 'c'
                      ? true
                      : false
                  "
                  >{{ currency }}</span
                >
                &nbsp;&nbsp;&nbsp;&nbsp;{{ item.statisticvalue }}
              </div>
              <!-- item.statisticvalue === null ||
                  item.statisticvalue === undefined
                    ? 0
                    : -->
              <div class="content_cards">
                <div class="scroll" ref="evabox">
                  <draggable
                    :options="dragOptions"
                    group="tasks"
                    @remove="remove($event, item.grouplabel)"
                    :id="item.grouplabel"
                    style="flex: 1"
                  >
                    <el-card
                      class="box-card"
                      shadow="hover"
                      v-for="(items, index) in item.dataList"
                      :key="index"
                      :id="items.id"
                      :data-id="item.statisticvalue"
                      :sex_wtd="items.sexWtd"
                      :company="items.company"
                      :owneridccname="items.owneridccname"
                      :name="items.name"
                      :email="items.email === '' ? '' : items.email"
                      :ownerid="items.ownerid === '' ? '' : items.ownerid"
                      @mousedown.native="card(items)"
                    >
                      <div
                        class="xiala"
                        v-if="isXiala && objectApi !== 'CloudccKArticle'"
                      >
                        <el-dropdown @command="handleCommand">
                          <span class="el-dropdown-link">
                            <i>
                              <svg class="icon guanbi" aria-hidden="true">
                                <use :href="'#icon-xiala'"></use>
                              </svg>
                            </i>
                          </span>
                          <el-dropdown-menu
                            slot="dropdown"
                            class="KanbanDropdown"
                          >
                            <el-dropdown-item
                              :command="beforeHandleCommand(items.id, 'a')"
                            >
                              <!-- 编辑 -->
                              <span class="operation">{{
                                $t("pagecreator_page_button_edit")
                              }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="beforeHandleCommand(items.id, 'b')"
                            >
                              <!-- 删除 -->
                              <span class="operation">{{
                                $t("component_setup_tabs_label_delete")
                              }}</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="beforeHandleCommand(items.id, 'c')"
                            >
                              <!-- 转移所有人 -->
                              <span class="operation">{{
                                $t("label_tabpage_cownerz")
                              }}</span>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>

                      <div class="conent_body">
                        <span
                          style="display: block; width: 80%"
                          v-for="(itemes, indexs) in apinames"
                          :key="indexs"
                        >
                          <span
                            style="
                              display: block;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                            "
                            v-if="itemes === 'name' ? false : true"
                          >
                            <a
                              style="color: #006dcc"
                              class="color"
                              :href="nameHrefUrl(itemes.apiname, items)"
                              @click="detail(itemes.apiname, items)"
                              v-if="
                                itemes.fieldtype !== 'SCORE' &&
                                ((itemes.fieldtype === 'Y' &&
                                  items.isshows === false) ||
                                  itemes.fieldtype === 'M' ||
                                  itemes.apiname === 'name')
                              "
                            >
                              {{
                                items[itemes.apiname] === "true"
                                  ? "是"
                                  : items[itemes.apiname] === "false"
                                  ? "否"
                                  : items[itemes.apiname]
                              }}</a
                            >
                            <span
                              style="color: #000"
                              v-else-if="
                                itemes.fieldtype !== 'SCORE' &&
                                (itemes.fieldtype !== 'Y' ||
                                  items.isshows !== false) &&
                                itemes.fieldtype !== 'M' &&
                                itemes.apiname !== 'name'
                              "
                            >
                              {{
                                items[itemes.apiname] === "true"
                                  ? "是"
                                  : items[itemes.apiname] === "false"
                                  ? "否"
                                  : items[itemes.apiname]
                              }}
                            </span>
                            <el-progress
                              v-if="
                                itemes.apiname === 'leadscore' ? true : false
                              "
                              :percentage="
                                items[itemes.apiname] !== undefined &&
                                items[itemes.apiname] !== null
                                  ? Number(items[itemes.apiname])
                                  : 0
                              "
                              :color="customColors"
                              :show-text="false"
                              style="
                                width: calc(100% - 50px);
                                display: inline-block;
                                margin-left: 5px;
                              "
                            ></el-progress>

                            <span
                              class="infoItem stars"
                              :style="{
                                width: item.width === '100%' ? '97.5%' : '95%',
                              }"
                              v-else-if="itemes.fieldtype === 'SCORE'"
                            >
                              <span
                                v-for="(star, idx) in Number(itemes.length) + 1"
                                v-show="idx !== 0"
                                :class="{
                                  show2: idx <= Number(items[itemes.apiname]),
                                }"
                                :key="idx"
                              >
                              </span>

                              <i
                                v-if="isEdit"
                                style="
                                  float: right;
                                  cursor: pointer;
                                  width: 14px;
                                  height: 14px;
                                  margin-right: 20px;
                                  display: none;
                                "
                                @click="edit(index)"
                                class="pen editableCell"
                              ></i>
                            </span>
                          </span>
                        </span>
                      </div>
                    </el-card>
                  </draggable>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div
      v-if="recordtypeGroup.length === 0 ? true : false"
      style="height: 100%"
    >
      <div class="box_main" v-loading="loadinges">
        <div class="box" v-for="(item, index) in groupData" :key="index">
          <div
            :class="[
              index === groupData.length - 1 ? 'header last-header' : 'header',
            ]"
          >
            <svg v-if="index == 0" class="icon" aria-hidden="true">
              <use href="#icon-kanban-step-1"></use>
            </svg>
            <svg
              v-else-if="index < groupData.length - 1"
              class="icon"
              aria-hidden="true"
            >
              <use href="#icon-kanban-step-2"></use>
            </svg>
            <svg v-else class="icon" aria-hidden="true">
              <use href="#icon-kanban-step-3"></use>
            </svg>
            <div class="box_header_content">
              <el-tooltip
                popper-class="my-tooltip"
                class="item"
                effect="dark"
                :content="item.grouplabel"
                placement="top"
              >
                <span class="span_tooltip"
                  >{{
                    item.grouplabel === "true"
                      ? $t("label_tabpage_yesz")
                      : item.grouplabel === "false"
                      ? $t("label.checkbox.false")
                      : item.grouplabel
                  }}
                  ({{ item.groupcounts }})</span
                >
              </el-tooltip>
            </div>
          </div>
          <div class="box_s" :class="[index !== 0 ? 'borderLeft' : '']">
            <div
              :class="item.statisticvalue !== undefined ? 'total' : 'totals'"
            >
              <span
                v-if="
                  statisticField !== undefined &&
                  statisticField.fieldtype === 'c'
                    ? true
                    : false
                "
                >{{ currency }}</span
              >
              &nbsp;&nbsp;&nbsp;&nbsp;{{ item.statisticvalue }}
            </div>
            <div class="content_cards">
              <div class="scroll" ref="evabox">
                <draggable
                  :options="dragOptions"
                  group="tasks"
                  @remove="remove($event, item.grouplabel)"
                  :id="item.grouplabel"
                  style="flex: 1"
                >
                  <el-card
                    class="box-card"
                    shadow="hover"
                    v-for="(items, index) in item.dataList"
                    :key="index"
                    :id="items.id"
                    :data-id="item.statisticvalue"
                    :sex_wtd="items.sexWtd"
                    :company="items.company"
                    :owneridccname="items.owneridccname"
                    :name="items.name"
                    :email="items.email === '' ? '' : items.email"
                    :ownerid="items.ownerid === '' ? '' : items.ownerid"
                    @mousedown.native="card(items)"
                  >
                    <div class="xiala" v-if="isXiala">
                      <el-dropdown @command="handleCommand">
                        <i>
                          <svg class="icon guanbi" aria-hidden="true">
                            <use :href="'#icon-xiala'"></use>
                          </svg>
                        </i>
                        <el-dropdown-menu
                          slot="dropdown"
                          class="KanbanDropdown"
                        >
                          <el-dropdown-item
                            :command="beforeHandleCommand(items.id, 'a')"
                          >
                            <!-- 编辑 -->
                            {{ $t("pagecreator_page_button_edit") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="beforeHandleCommand(items.id, 'b')"
                          >
                            <!-- 删除 -->
                            {{ $t("component_setup_tabs_label_delete") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="beforeHandleCommand(items.id, 'c')"
                          >
                            <!-- 转移所有人 -->
                            {{ $t("label_tabpage_cownerz") }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>

                    <div class="conent_body">
                      <span
                        style="display: block"
                        v-for="(itemes, indexs) in apinames"
                        :key="indexs"
                      >
                        <span
                          style="
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                          v-if="itemes === 'name' ? false : true"
                        >
                          <a
                            style="color: #006dcc"
                            class="color"
                            :href="nameHrefUrl(itemes.apiname, items)"
                            @click="detail(itemes.apiname, items)"
                            v-if="
                              itemes.fieldtype !== 'SCORE' &&
                              (itemes.fieldtype === 'Y' ||
                                itemes.fieldtype === 'M' ||
                                itemes.apiname === 'name')
                            "
                            >{{
                              items[itemes.apiname] === "true"
                                ? "是"
                                : items[itemes.apiname] === "false"
                                ? "否"
                                : items[itemes.apiname]
                            }}</a
                          >
                          <span
                            style="color: #000"
                            v-else-if="
                              itemes.fieldtype !== 'SCORE' &&
                              itemes.fieldtype !== 'Y' &&
                              itemes.fieldtype !== 'M' &&
                              itemes.apiname !== 'name'
                            "
                          >
                            {{
                              items[itemes.apiname] === "true"
                                ? "是"
                                : items[itemes.apiname] === "false"
                                ? "否"
                                : items[itemes.apiname]
                            }}
                          </span>
                          <el-progress
                            v-if="itemes.apiname === 'leadscore' ? true : false"
                            :percentage="Number(items[itemes.apiname])"
                            :color="customColors"
                            style="
                              width: calc(100% - 50px);
                              display: inline-block;
                            "
                          ></el-progress>
                          <!-- 评分字段 -->
                          <span
                            class="infoItem stars"
                            :style="{
                              width: item.width === '100%' ? '97.5%' : '95%',
                            }"
                            v-else-if="itemes.fieldtype === 'SCORE'"
                          >
                            <span
                              v-for="(star, idx) in Number(itemes.length) + 1"
                              v-show="idx !== 0"
                              :class="{
                                show2: idx <= Number(items[itemes.apiname]),
                              }"
                              :key="idx"
                            >
                            </span>

                            <i
                              v-if="isEdit"
                              style="
                                float: right;
                                cursor: pointer;
                                width: 14px;
                                height: 14px;
                                margin-right: 20px;
                                display: none;
                              "
                              @click="edit(index)"
                              class="pen editableCell"
                            ></i>
                          </span>
                        </span>
                      </span>
                    </div>
                  </el-card>
                </draggable>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 看图数据显示 -->

    <!-- 看图设置 -->
    <el-dialog
      :title="$t('vue_label_commonobjects_view_settings')"
      :visible.sync="dialogVisible"
      class="dialog"
      modal
      width="30%"
      show-close
    >
      <el-form class="demo-form-inline" :rules="rules">
        <!-- 分类依据 -->
        <el-form-item
          :label="$t('vue_label_commonobjects_view_basis')"
          hide-required-asterisk
          prop="statisticfield"
        >
          <el-select class="select" filterable v-model="classificationBasis">
            <el-option
              v-for="(item, index) in groupFieldList"
              :key="index"
              :label="item.fieldlabel"
              :value="item.fieldid"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 汇总依据 -->
        <el-form-item :label="$t('vue_label_commonobjects_view_summary_basis')">
          <el-select
            class="select"
            filterable
            clearable
            @clear="clears"
            v-model="summaryBasis"
          >
            <el-option
              v-for="(item, index) in statisticFieldList"
              :key="index"
              :label="item.fieldlabel"
              :value="item.fieldid"
              @click.native="change(item.type, item)"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 汇总方式 -->
        <el-form-item :label="$t('label_tabpage_summaryway')">
          <el-select
            class="select"
            :disabled="isSummaryMethod"
            filterable
            v-model="summaryMethod"
          >
            <el-option
              v-for="(item, index) in statistictypes"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button type="primary" @click="GetViewKanbanSaves">
          <!-- 确定 -->
          {{ $t("label.ems.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 新增/修改记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :id="id"
      :objectApi="objectApi"
      :object-name="tabName"
      @save="save"
    >
    </create-edit-obj>
    <div class="deletel">
      <el-dialog
        :visible.sync="deletels"
        :title="$t('component_setup_tabs_label_delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
      >
        <span style="font-size: 14px; color: #080707; word-break: break-word">
          <!-- 确认删除该视图吗？ -->
          {{ $t("lable.are.you.sure.to.delete.this.view") }}?
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deletels = false" size="mini">
            <!-- 取消 -->
            {{ $t("button_partner_cancel") }}
          </el-button>
          <el-button type="primary" @click="dels" size="mini">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}
          </el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 更改所有人 -->
    <transfer-owner-dialog
      ref="transferClientOwner"
      :id="id"
      :objId="objId"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      :transfer-client-owner-dialog="transferOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    >
    </transfer-owner-dialog>
  </div>
</template>

<script>
import createEditObj from "../components/create-edit-obj";
import draggable from "vuedraggable";
import {
  GetViewKanbanDisplay,
  GetViewKanbanEdit,
  GetViewKanbanSave,
} from "./api.js";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import { TransferOwnerDialog } from "@/components/index";
import * as CommonObjApi from "./api";
import Bus from "@/mixin/bus.js";

export default {
  mixins: [TransferOwnerMixin],
  props: {
    kanBanData: {
      type: Object,
    },
    ismodifyFalg: {
      type: String,
      default: "",
    },
  },
  components: {
    draggable,
    createEditObj,
    TransferOwnerDialog,
  },
  created() {
    this.GetObjectdetailGetObjectPermissions();
    this.getUser();
    this.load = true;
    this.isChat = true;
    if (this.viewId.length < 4) {
      this.$router.go(-1);
    } else {
      this.GetViewKanbanDisplays();
      // this.GetViewKanbanEdits();
    }
  },
  mounted() {
    Bus.$on("windowResize", this.windowResizeFn);
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.windowResizeFn);
  },
  data() {
    return {
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      isChat: false,
      isIf: false,
      dialogVisible: false,
      checkList: [], //是否勾选客户多选框
      input2: "", //搜索框
      whole: [], //全部数据
      groupData: [], //分组数据
      kanbanInfo: {}, //看板信息

      groupFieldList: [], //可分组字段  分类依据
      statisticFieldList: [], //汇总字段 汇总依据
      statistictypes: [], //合计类型 汇总方式
      fieldList: [], //可选显示字段  可选字段
      selectedFieldList: [], //已选择字段列表 已选字段
      viewId: this.$route.query.viewId, //视图id
      classificationBasis: "", //分类依据 分组字段
      summaryBasis: "", //汇总依据 统计字段id
      summaryMethod: "", //汇总方式 统计类型(COUNT,SUM,AVG)
      radio: "asc", //排序选择 排序方式(asc,desc)
      objectApi: this.$route.params.objectApi,
      restaurants: [],
      rules: {
        statisticfield: [
          {
            required: true,
            message:
              this.$i18n.t("label_tabpage_pleaseSelectz") +
              this.$i18n.t("vue_label_commonobjects_view_basis"), //"请选择分类依据",
            trigger: "blur",
          },
        ],
      },
      currency: "", //币种
      objId: this.$route.params.objId,
      prefix: this.$route.params.prefix,
      tabName: this.$route.params.tabName,
      submitId: "",
      id: "",
      loadinges: false,
      load: false,
      fieldtype: "",
      deletels: false,
      deleteInfo: {},
      statisticField: {},
      apinames: [],
      // 转移所有人
      transferOwnerDialog: false,
      // ownerName: "",
      activeName: null,
      listFieldInfo: [],
      ifdraggable: false,
      objectes: {
        add: "", //增加
        delete: "", //删除
        modify: "", //编辑
        modifyAll: "", // 修改全部
        query: "", //查看
        queryAll: "", //查看全部
      },
      record: {
        canEditOwner: "", //更改所有人
        isDelete: "", //删除
        isEdit: "", //编辑
        isLocked: "", //记录锁定
        isQuery: "", // 查看
        isXiala: true,
      },
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      recordtypeGroup: [],
      isRecordtypeGroup: false,
      isTips: true,
      ifdraggables: true,
      getUserInfo: {},
      isSummaryMethod: true,
      showKanbanView: false,
      isdraggable: false, //默认启用拖拽
      tabNames: null,
    };
  },
  methods: {
    windowResizeFn(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    change(value) {
      if (
        value == "ct" ||
        value == "P" ||
        value == "SCORE" ||
        value == "N" ||
        value == "C" ||
        value == "c" ||
        value == "n" ||
        value == "p" ||
        value == "score"
      ) {
        this.statistictypes = [
          // 求和
          {
            label: this.$i18n.t("label.summation"),
            value: "SUM",
          },
          {
            label: this.$i18n.t("label.average"),
            value: "AVG",
            //平均值
          },
        ];
      } else {
        this.statistictypes = [];
      }
    },
    /* 
      拖动看板：拖拽时需要请求的接口：
        1.是否有操作权限接口；
        2.保存接口；
        3.获取数据接口；请求完接口后仅更新数量即可。
    */
    async remove(evt) {
      // 判断看板是否有修改权限，即是否可被拖拽
      if (this.ifdraggables === false) {
        this.activeName = "";
        this.$message.error(this.$i18n.t("label.weixin.donot.permission"));
        this.GetObjectdetailGetObjectPermissions();
        this.load = true;
        this.isChat = true;
        this.GetViewKanbanDisplays();
        // this.GetViewKanbanEdits();
        return;
      }
      // this.loadinges = true;
      let datas = [];
      if (evt.item.attributes.email === undefined) {
        if (this.fieldtype.indexOf("id") !== -1) {
          this.fieldtype = this.fieldtype + "ccname";
        }

        let objectes = {
          id: evt.item.id,
        };
        objectes[this.fieldtype] = evt.to.id;
        datas.push(objectes);
      } else {
        let objectes = {
          id: evt.item.id,
        };
        objectes[this.fieldtype] = evt.to.id;
        datas.push(objectes);
      }

      let params = {
        objectApi: this.objectApi,
        data: JSON.stringify(datas),
      };
      let res = await CommonObjApi.saveObjectInfo(params);
      if (res.result) {
        res.data.forEach((item) => {
          if (item.isSaveSuccess !== "true") {
            this.$message.error(item.errormessage);
            this.GetViewKanbanDisplays(this.tabNames);
          } else {
            this.GetViewKanbanDisplays(this.tabNames, "", true);
          }
        });
      } else {
        this.$message.error(this.$i18n.t("chatter.save.f"));
      }
    },
    // removes(evt) {
    //   this.submitId = evt.item.id;
    // },
    /* 
      调用看板展示数据：
        根据参数onlyUpdateCount判断，当只需要更新数量时，则不需要进行loading和清空数据
    */
    async GetViewKanbanDisplays(tabName, id, onlyUpdateCount) {
      let searchs = this.$parent.$refs.searchWrapper.search;
      if (!onlyUpdateCount) {
        this.loadinges = true;
      }
      let params = {
        viewId: id || this.viewId,
        recordType:
          tabName === undefined
            ? ""
            : tabName === ""
            ? this.activeName
            : tabName,
        searchKeyWord:
          searchs !== "" && searchs !== undefined && searchs !== null
            ? searchs
            : "",
      };
      let res = await GetViewKanbanDisplay(params);
      // 判断是否仅更新数量，若是仅更新数量则不需要清空，反之则清空。
      if (!onlyUpdateCount) {
        this.fieldtype = "";
        this.statisticField = "";
        this.currency = "";
        this.apinames = [];
        this.whole = [];
        this.groupData = [];
      }
      if (res.data === null) {
        this.showKanbanView = false;
        this.load = false;
        this.loadinges = false;
        // 判断是否可进行看板设置
        if (this.noClicks()) {
          // this.$message.warning("对不起，您当前并未有新的看板，请创建");
          this.GetViewKanbanEdits();
          setTimeout(() => {
            this.dialogVisible = true;
          }, 2000);
        }
      } else {
        this.showKanbanView = true;
        // 判断是否仅更新数量
        if (onlyUpdateCount) {
          res.data.groupData.forEach((item, index) => {
            this.groupData[index].groupcounts = item.groupcounts;
            this.groupData[index].statisticvalue = item.statisticvalue;
          });
          this.loadinges = false;
          this.load = false;
          return;
        }
        res.data.groupData.forEach((item) => {
          if (item.dataList === undefined || item.dataList === null) {
            item.dataList = [];
          } else {
            item.dataList.forEach((items) => {
              items.isshows = false; //判断所有人是否为队列
              if (items.ownerid !== undefined && items.ownerid !== null) {
                if (items.ownerid.substring(0, 3) !== "005") {
                  items.isshows = true;
                }
              }
            });
            if (
              item.statisticvalue !== undefined &&
              item.statisticvalue !== ""
            ) {
              item.statisticvalue = parseFloat(
                item.statisticvalue
              ).toLocaleString();
            }
          }
        });
        if (this.isRecordtypeGroup === false) {
          if (
            res.data.recordtypeGroup === undefined ||
            res.data.recordtypeGroup.length === 0
          ) {
            this.recordtypeGroup = [];
          } else {
            this.recordtypeGroup = res.data.recordtypeGroup;
          }
        }

        if (this.isTips) {
          if (res.data.totalCount === 200) {
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              //看板视图只显示前200条记录
              //   要查看所有记录，请使用列表或分屏视图
              message: `<p><p style='font-size:14px' >${this.$i18n.t(
                "vue_label_commonobjects_view_kanban_notice1"
              )}</p> <br /> <p style='font-size:12px'>${this.$i18n.t(
                "vue_label_commonobjects_view_kanban_notice2"
              )}</p></p>`,
            });
          }

          this.isTips = false;
        }

        //获取展示字段
        this.fieldtype = res.data.groupField.apiname;
        //获取设置信息
        this.statisticField = res.data.statisticField;
        //获取币种
        this.currency = res.data.currency;
        this.whole = res.data;
        //获取展示字段
        this.listFieldInfo = res.data.listFieldInfo;

        if (res.data.groupField.fieldtype === "Y") {
          // fieldtype:Y
          this.isdraggable = true;
        } else {
          this.isdraggable = false;
        }
        this.apinames = [];
        this.listFieldInfo.forEach((item) => {
          if (
            item.apiname.indexOf("id") !== -1 ||
            item.fieldtype === "Y" ||
            item.fieldtype === "R" ||
            item.fieldtype === "M" ||
            item.fieldtype === "MR"
          ) {
            item.apiname = item.apiname + "ccname";
          }

          let obj = {};
          if (item.apiname === "name") {
            obj.apiname = item.apiname;
            obj.fieldtype = item.fieldtype;
            this.apinames.unshift(obj);
          } else if (item.fieldtype === "SCORE") {
            obj.length = 20;
            obj.apiname = item.apiname;
            obj.fieldtype = item.fieldtype;
            this.apinames.push(obj);
          } else {
            obj.apiname = item.apiname;
            obj.fieldtype = item.fieldtype;
            this.apinames.push(obj);
          }
        });
        if (this.recordtypeGroup.length !== 0) {
          if (this.activeName === null || this.activeName === "") {
            this.activeName =
              tabName !== undefined && tabName !== ""
                ? tabName
                : res.data.recordtypeGroup[0].recordtype === ""
                ? "0"
                : res.data.recordtypeGroup[0].recordtype;
          }
        }
        this.groupData = res.data.groupData;
        this.isRecordtypeGroup = false;
        this.loadinges = false;
        this.load = false;
      }
    },

    //看板视图设置数据获取
    async GetViewKanbanEdits() {
      let params = {
        viewId: this.viewId,
      };
      let res = await GetViewKanbanEdit(params);
      this.groupFieldList = res.data.groupFieldList;
      let statisticFieldLists = [];
      res.data.statisticFieldList.forEach((item) => {
        statisticFieldLists.push(item);
      });
      this.statisticFieldList = statisticFieldLists;
      this.selectedFieldList = res.data.selectedFieldList;
      this.fieldList = res.data.fieldList;
      this.statistictypes = res.data.statistictypes;
      this.kanbanInfo = res.data.kanbanInfo;
      this.restaurants = this.fieldList;
      if (res.data.kanbanInfo && res.data.kanbanInfo.groupfield) {
        this.classificationBasis = res.data.kanbanInfo.groupfield;
      }
      this.summaryBasis = res.data.kanbanInfo.statisticfield;
      this.summaryMethod = res.data.kanbanInfo.statistictype;
    },

    //看板视图保存
    async GetViewKanbanSaves() {
      if (this.classificationBasis === "" || this.fieldList === "") {
        // 请选择必填项
        this.$message.info(this.$i18n.t("ue_label_lead_required_fields"));
      } else {
        if (this.isIf === false) {
          let params = {
            viewid: this.viewId,
            statistictype: this.summaryMethod,
            statisticfield: this.summaryBasis,
            groupfield: this.classificationBasis,
            sorttype: this.radio,
            displayfields: "",
          };

          let res = await GetViewKanbanSave(params);

          if (res.result) {
            this.activeName = "";
            this.isRecordtypeGroup = false;
            this.GetViewKanbanDisplays();
            this.$message.success(this.$i18n.t("savesuccess"));
            // 保存视图成功
            this.dialogVisible = false;
          } else {
            //保存视图失败，请检查数据
            this.$message.error(this.$i18n.t("savefail"));
          }
        } else {
          let params = {
            id: this.kanbanInfo.id,
            viewid: this.viewId,
            statistictype: this.summaryMethod,
            statisticfield: this.summaryBasis,
            groupfield: this.classificationBasis,
            // sortfield: this.sortBy,
            sorttype: this.radio,
            displayfields: "",
          };
          let res = await GetViewKanbanSave(params);
          if (res.result) {
            this.$message.success(this.$i18n.t("savesuccess"));
            this.dialogVisible = false;
            this.isIf = false;
            this.activeName = "";
            this.isRecordtypeGroup = false;
            this.GetViewKanbanDisplays();
          } else {
            this.$message.error(this.$i18n.t("savefail"));
          }
        }
      }
    },
    beforeHandleCommand(index, command) {
      return {
        index: index,
        command: command,
      };
    },
    async handleCommand(command) {
      if (command.command === "a") {
        this.id = command.index;

        let params = {
          id: command.index,
        };
        let res = await CommonObjApi.getPermissionById(params);
        //点击编辑按钮时判断是否有编辑权限
        if (res.result) {
          this.record.canEditOwner = res.data.canEditOwner;
          this.record.isDelete = res.data.isDelete;
          this.record.isEdit = res.data.isEdit;
          this.record.isLocked = res.data.isLocked;
          this.record.isQuery = res.data.isQuery;
        }
        if (res.data.isLocked) {
          if (this.getUserInfo.profileId === "aaa000001") {
            this.$refs.createEditObj.edit();
          } else {
            //  "记录已锁定:您试图编辑的记录已锁定。如果必须执行此操作，请与您的管理员联系。"
            this.$message.error(this.$i18n.t("message.datalocked.delete"));
          }
        } else if (res.data.isEdit !== true) {
          this.$message({
            showClose: true,
            message: this.$i18n.t("report_label_norole"),
            type: "warning",
          });
          //"抱歉，您无权编辑该条数据，请联系系统管理员。"
        } else {
          this.$nextTick(() => {
            this.$refs.createEditObj.edit();
          });
        }
      }
      if (command.command === "b") {
        this.id = command.index;
        let params = {
          id: command.index,
        };
        let res = await CommonObjApi.getPermissionById(params);
        //点击删除按钮时判断是否有删除权限
        if (res.result) {
          this.record.canEditOwner = res.data.canEditOwner;
          this.record.isDelete = res.data.isDelete;
          this.record.isEdit = res.data.isEdit;
          this.record.isLocked = res.data.isLocked;
          this.record.isQuery = res.data.isQuery;
        }
        if (res.data.isLocked) {
          if (this.getUserInfo.profileId === "aaa000001") {
            this.deleteInfo = command;
            this.deletels = true;
          } else {
            this.$message.error(this.$i18n.t("message.datalocked.delete"));
          }
        } else if (this.record.isDelete !== true) {
          this.$message({
            showClose: true,
            message: this.$i18n.t("report_label_norole"),
            type: "warning",
          });
          //"抱歉，您无权编辑该条数据，请联系系统管理员。"
        } else {
          this.deleteInfo = command;
          this.deletels = true;
        }
      }
      if (command.command === "c") {
        this.id = command.index;
        let params = {
          id: command.index,
        };
        let res = await CommonObjApi.getPermissionById(params);
        //点击转移所有人按钮时判断是否有转移所有人权限
        if (res.result) {
          this.record.canEditOwner = res.data.canEditOwner;
          this.record.isDelete = res.data.isDelete;
          this.record.isEdit = res.data.isEdit;
          this.record.isLocked = res.data.isLocked;
          this.record.isQuery = res.data.isQuery;
        }
        if (res.data.isLocked) {
          if (this.getUserInfo.profileId === "aaa000001") {
            this.initTransferOwner(this.objId, this.id);
            this.transferOwnerDialog = true;
          } else {
            this.$message.error(
              // '记录已锁定:您试图编辑的记录已锁定。如果必须执行此操作，请与您的管理员联系。'
              this.$i18n.t("message.datalocked")
            );
          }
        }
        if (this.record.canEditOwner !== true) {
          this.$message({
            showClose: true,
            message: this.$i18n.t("report_label_norole"),
            type: "warning",
          });
          //"抱歉，您无权编辑该条数据，请联系系统管理员。"
        } else {
          this.initTransferOwner(this.objId, this.id);
          this.transferOwnerDialog = true;
        }
      }
    },
    async dels() {
      let params = {
        id: this.deleteInfo.index,
        objectApi: this.objectApi,
      };
      let res = await CommonObjApi.del(params);
      if (res.result) {
        this.deletels = false;
        this.GetViewKanbanDisplays();
        // '删除成功'
        this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
      }
    },
    hideTool() {
      this.$emit("hideTool");
    },
    // 父组件顶部按钮中点击看板设置（父组件调用）
    async open() {
      this.GetViewKanbanEdits();
      this.$nextTick(() => {
        this.dialogVisible = true;
        this.isIf = true;
      });
    },
    querySearchAsync(queryString) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;

      this.fieldList = results;

      this.fieldList.forEach((item, index) => {
        if (item.id === this.submitId) {
          this.fieldList.splice(index, 1);
        }
      });
    },
    createStateFilter(queryString) {
      return (input2) => {
        return (
          input2.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    detail(id, val) {
      if (id.indexOf("ccname") !== -1) {
        let res = id.replace("ccname", "");
        let routerJump = this.$router.resolve({
          path: `/commonObjects/detail/${val[res]}/DETAIL`,
        });
        window.open(routerJump.href, "_self");
      } else {
        let routerJump = this.$router.resolve({
          path: `/commonObjects/detail/${val.id}/DETAIL`,
        });
        window.open(routerJump.href, "_self");
      }
    },
    nameHrefUrl(id, val) {
      if (id.indexOf("ccname") !== -1) {
        let res = id.replace("ccname", "");
        return val[res] ? `#/commonObjects/detail/${val[res]}/DETAIL` : "";
      } else {
        return val.id ? `#/commonObjects/detail/${val.id}/DETAIL` : "";
      }
    },
    // 新增/修改
    save(val) {
      if (val !== "") {
        this.GetViewKanbanDisplays();
      }
    },
    closeTransferOwnerDialog() {
      this.transferOwnerDialog = false;
    },
    // 获取对象权限
    async GetObjectdetailGetObjectPermissions() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.getObjectPermission(params);
      if (res.result) {
        this.objectes.add = res.data.add; //添加
        this.objectes.delete = res.data.delete; //删除
        this.objectes.modify = res.data.modify; //编辑
        this.objectes.modifyAll = res.data.modifyAll; //修改全部
        this.objectes.query = res.data.query; //查看
        this.objectes.queryAll = res.data.queryAll; //查看全部
      }
      if (this.objectes.modify) {
        this.isXiala = true;
        this.ifdraggable = false;
      } else {
        this.isXiala = false;
        this.ifdraggable = true;
      }
    },
    handleClick(tab) {
      this.isRecordtypeGroup = true;
      this.tabNames = tab.name;
      // 此时传入tabName到GetViewKanbanDisplays,是为了防止tabName为空时activeName为标签序列号
      this.GetViewKanbanDisplays(tab.name);
    },
    async card(val) {
      let params = {
        id: val.id,
      };
      let res = await CommonObjApi.getPermissionById(params);
      if (res.result) {
        this.record.canEditOwner = res.data.canEditOwner;
        this.record.isDelete = res.data.isDelete;
        this.record.isEdit = res.data.isEdit;
        this.record.isLocked = res.data.isLocked;
        this.record.isQuery = res.data.isQuery;
      }

      this.ifdraggables = res.data.isEdit;
    },
    async getUser() {
      let res = await CommonObjApi.getUserInfo();
      if (res.result) {
        this.getUserInfo = res.data;
      }
    },
    clears() {
      this.summaryMethod = "";
    },
    // 点击看板设置
    opens() {
      this.GetViewKanbanEdits();
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    // 根据父组件传过来的视图是否具有编辑权限决定看板是否可设置
    noClicks() {
      if (this.ismodifyFalg === "true") {
        return true;
      } else {
        return false;
      }
    },
  },

  updated() {
    this.viewid = this.viewId;
  },
  computed: {
    dragOptions() {
      let that = this;
      return {
        animation: 1000,
        dataIdAttr: "1",
        disabled: that.isdraggable === false ? false : true,
      };
    },
  },
  watch: {
    viewId() {},
    checkList(value) {
      this.$emit("longitude", value.length);
      let str = "";
      for (var i = 0; i < value.length; i++) {
        str += value[i] + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }
      // this.str = str

      this.$emit("chat", str);
    },

    isChat(val) {
      this.$emit("isChat", val);
    },
    summaryBasis(val) {
      if (val !== "" && val !== undefined) {
        this.isSummaryMethod = false;
        this.summaryMethod = "SUM";
      } else {
        this.isSummaryMethod = true;
      }
    },
    kanBanData(val) {
      if (val !== undefined && val !== null) {
        this.loadinges = true;
        this.fieldtype = "";
        this.statisticField = "";
        this.currency = "";
        this.apinames = [];
        this.whole = [];
        this.groupData = [];

        this.showKanbanView = true;
        val.groupData.forEach((item) => {
          if (item.dataList === undefined || item.dataList === null) {
            item.dataList = [];
          } else {
            item.dataList.forEach((items) => {
              items.isshows = false; //判断所有人是否为队列
              if (items.ownerid !== undefined && items.ownerid !== null) {
                if (items.ownerid.substring(0, 3) !== "005") {
                  items.isshows = true;
                }
              }
            });
            if (
              item.statisticvalue !== undefined &&
              item.statisticvalue !== ""
            ) {
              item.statisticvalue = parseFloat(
                item.statisticvalue
              ).toLocaleString();
            }
          }
        });
        if (this.isRecordtypeGroup === false) {
          if (
            val.recordtypeGroup === undefined ||
            val.recordtypeGroup.length === 0
          ) {
            this.recordtypeGroup = [];
          } else {
            this.recordtypeGroup = val.recordtypeGroup;
          }
        }

        if (this.isTips) {
          if (val.totalCount === 200) {
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              //看板视图只显示前200条记录
              //   要查看所有记录，请使用列表或分屏视图
              message: `<p><p style='font-size:14px' >${this.$i18n.t(
                "vue_label_commonobjects_view_kanban_notice1"
              )}</p> <br /> <p style='font-size:12px'>${this.$i18n.t(
                "vue_label_commonobjects_view_kanban_notice2"
              )}</p></p>`,
            });
          }

          this.isTips = false;
        }

        //获取展示字段
        this.fieldtype = val.groupField.apiname;
        //获取设置信息
        this.statisticField = val.statisticField;
        //获取币种
        this.currency = val.currency;
        this.whole = val;
        //获取展示字段
        this.listFieldInfo = val.listFieldInfo;

        // if (val.groupField.apiname === 'ownerid') {
        //   // ownerid:所有人
        //   this.isdraggable = true
        // } else {
        //   this.isdraggable = false
        // }
        this.isdraggable = false;
        this.apinames = [];
        this.listFieldInfo.forEach((item) => {
          if (
            item.apiname.indexOf("id") !== -1 ||
            item.fieldtype === "Y" ||
            item.fieldtype === "R" ||
            item.fieldtype === "M" ||
            item.fieldtype === "MR"
          ) {
            item.apiname = item.apiname + "ccname";
          }

          let obj = {};
          if (item.apiname === "name") {
            obj.apiname = item.apiname;
            obj.fieldtype = item.fieldtype;
            this.apinames.unshift(obj);
          } else {
            obj.apiname = item.apiname;
            obj.fieldtype = item.fieldtype;
            this.apinames.push(obj);
          }
        });
        if (this.recordtypeGroup.length !== 0) {
          this.recordtypeGroup.forEach((item) => {
            if (item.recordtype !== this.activeName) {
              // this.activeName = this.activeName;
              this.activeName = val.recordtypeGroup[0].recordtype;
            }
          });
          this.GetViewKanbanDisplays(this.activeName);
          return;
        }
        this.groupData = val.groupData;
        this.isRecordtypeGroup = false;
        this.loadinges = false;
        this.load = false;
      }
    },
    activeName(val) {
      this.$emit("activeName", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-tips {
  position: absolute;
  top: 10px;
  right: 18px;
  width: 14px;
  height: 14px;
  display: block;
  z-index: 100;
}

.KanbanDropdown {
  padding: 6px 0px 7px 0px !important;
  width: 92px !important;
  .el-dropdown-menu__item {
    font-size: 12px !important;
    padding-left: 10px !important;
    color: #3e3e3c !important;
    line-height: 28px !important;
  }
  .el-dropdown-menu__item:hover {
    background: #f3f2f2;
    color: #006dcc !important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .objectTableMain .object_box {
  height: 100% !important;
}
.home {
  ::v-deep .el-tabs__item {
    color: #3e3e3c;
  }
  ::v-deep .el-tabs__item:hover {
    color: #080707;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #080707;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    height: 0;
  }
  ::v-deep .el-tabs__active-bar {
    background-color: #006dcc;
  }
  ::v-deep .el-tabs__header {
    padding: 0 20px;
    border-bottom: 2px solid #d8d8d8;
  }
}
.home {
  flex: 1;
  overflow: auto;
  float: left;
  // height: 89%;
  width: 100%;
  background-color: #ffffff;
  // overflow-x: auto;
  // overflow-y: hidden;
  position: relative;
  // height: 89.5%;

  .box_main {
    padding: 8px 10px 4px 10px;
    white-space: nowrap;
    height: 100%;
    overflow-x: auto;
    display: flex;

    .box {
      height: 100%;
      width: 274px;
      margin-right: 3px;
      border-bottom: 0px;
      // display: inline-block;
      display: flex;
      flex-direction: column;

      .box_s {
        border: 1px solid rgba(221, 219, 218, 1);
        background: rgba(255, 255, 255, 1);
        // height: 88.5%;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .total {
          height: 60px;
          text-align: center;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          color: #06844b;
          letter-spacing: 0;
          padding: 20px 60px;
        }
        .totals {
          display: none;
        }
        .content_cards {
          overflow: auto;
          // width: 276px;
          flex: 1;
        }
      }

      .header {
        width: 284px;
        height: 40px;
        border: 0;
        border-radius: 3px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 40px;
        text-align: center;
        background-size: 100%;

        .icon {
          width: 100%;
          height: 100%;
        }

        .box_header_content {
          font-family: SourceHanSansCN-Medium;
          font-size: 12px;
          color: #ffffff;
          position: relative;
          top: -127%;

          .span_tooltip {
            cursor: pointer;
            max-width: 125px;
            overflow: hidden;
            display: inline-block;
            margin-right: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .last-header {
        width: 274px;
      }

      .box-card {
        // width: 254px;
        background: rgba(255, 255, 255, 1);
        margin: 10px;
        cursor: pointer;
        min-height: 50px;

        .title {
          // width: 200px;

          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: rgba(0, 109, 204, 1);
          float: left;
          position: relative;
          top: 5px;

          ::v-deep .el-checkbox {
            margin-right: 10px;
          }

          ::v-deep .el-checkbox__label {
            display: none;
          }
        }

        .titles {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 150px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: rgba(0, 109, 204, 1);
          position: relative;
          top: -20px;
        }

        .xiala {
          float: right;
          position: relative;
        }

        .guanbi {
          width: 19px;
          height: 19px;
          float: right;
        }
      }
    }
  }

  .choice {
    width: 56px;
    height: 19px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    float: left;
  }

  .radio {
    position: relative;
    top: 4px;
  }
}

.scroll {
  height: 100%;
  overflow: auto;
  border-bottom: none;
  position: relative;
  top: -5px;
  display: flex;
  flex-direction: column;
}

.row {
  margin-top: -10px;
  padding-bottom: 20px;
}

.dragBox {
  width: 50%;
}

.itemBox {
  width: 265px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 0;
  min-height: 200px;
  max-height: 200px;
  overflow: auto;
}

.dragItem {
  width: 100%;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  padding: 0 10px;
  text-align: left;
  padding-left: 22px;
  cursor: move;

  &:hover {
    background: #ddd;
  }
}

.btns {
  width: 100%;
  margin: 10px 15px 10px 0;
  text-align: right;
}

.search {
  position: relative;
  top: -7px;
  width: 239px;
  border-bottom: 1px solid #dcdcdc;
  margin: 0 auto;

  ::v-deep .el-input__inner {
    width: 100%;
    border: none;
  }
}

.name {
  color: #006dcc;
}

::v-deep .el-card__body {
  padding: 10px;
}

::v-deep .el-autocomplete-suggestion .el-popper .is-loading {
  transform-origin: center top !important;
  z-index: 2006 !important;
  width: 205px !important;
  display: none !important;
}

::v-deep .el-input__icon {
  line-height: 30px !important;
}

::v-deep .searchs {
  .el-input__prefix {
    .el-icon-search {
      margin-top: 0px !important;
    }
  }
}

::v-deep .el-checkbox-group {
  width: 200px;
}

.color {
  // color: #006dcc;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-tabs__content {
    // height: 93%;
    flex: 1;
    .el-tab-pane {
      height: 100%;
    }
  }

  ::v-deep .el-tabs__nav-wrap {
    padding-left: 15px;
    .el-tabs__nav-scroll {
      margin-left: -20px;
    }

    .el-tabs__item {
      padding: 0 20px;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      color: #3e3e3c;
      letter-spacing: 0;
    }

    .is-active {
      font-weight: bold;
      font-family: SourceHanSansCN-Medium;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
  }
  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
}

::v-deep .dialog {
  min-width: 1246px;
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .el-form-item__label {
    margin-right: 30px;
  }
  .el-form-item__content {
    width: 100%;
    .el-select {
      width: 100%;
    }
  }
}

::v-deep .transfer-client-owner {
  .el-select {
    .el-input__icon {
      margin-top: 0px !important;
    }
  }
}

.borderLeft {
  border-left: none !important;
}

.top {
  position: absolute;
  top: 0;
  right: 0;
}

.bg_img {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/kanbangujia.svg");
  background-repeat: no-repeat;
  background-size: 100%;

  .bg_img_button {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -95px;
    width: 223px;
    text-align: center;
  }
}

.tops {
  width: 100%;
  height: 30px;
}

.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  line-height: 40px;
  color: #ccc;
  // margin-left: 30px;
  float: left;
}

.stars span {
  font-size: 23px;
  margin-left: 4px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}
::-webkit-scrollbar {
  width: 0;
}
</style>
