<!-- 删除组件弹框 -->
<template>
  <el-dialog
    :title="isShowtitile"
    :visible="isShowDeleteAlert"
    width="450px"
    :before-close="deleteCancel"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <div class="center_center">
      <div>
        <p>{{ isShowContent }}</p>
        <p>
          <!-- 此项操作不可撤回 -->
          {{ $t("vue_label_norm_undone") }}
        </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="deleteAffirm">{{
        $t("component_setup_tabs_label_delete")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isShowDeleteAlert: {
      type: Boolean,
      default: false,
    },
    type: {
      // 删除类型：组件、图表
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    /**
     * 显示标题
     */
    isShowtitile() {
      if (this.$store.state.userInfoObj.language == "zh") {
        return this.$i18n.t("component_setup_tabs_label_delete") + this.type;
      } else {
        return (
          this.$i18n.t("component_setup_tabs_label_delete") + " " + this.type
        );
      }
    },
    isShowContent() {
      if (this.$store.state.userInfoObj.language == "zh") {
        return this.$i18n.t("label.sure.delete") + this.type + ' "' +this.name + '" ?';
      } else {
        return (
          this.$i18n.t("label.sure.delete") +
          " " +
          this.type +
          ' "' +
          this.name +
          '" ?'
        );
      }
    },
  },
  methods: {
    deleteCancel() {
      this.$emit("deleteCancel");
    },
    deleteAffirm() {
      this.$emit("deleteAffirm");
    },
  },
};
</script>
<style lang="scss" scoped>
.center_center {
  div {
    p:first-child {
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
    }
  }
}
</style>