<!-- 暂无数据组件 -->
<template>
  <div class="div_temporarily_no_data">
    <!-- 无数据缺省图片 -->
    <svg class="img" aria-hidden="true">
      <use :href="`#${imgSrc}`"></use>
    </svg>
    <!-- 提示语 -->
    <div v-html="titleWord"></div>
  </div>
</template>

<script>
/**
 * @description 暂无数据
 * 功能：
 * 1、展示暂无数据图片和提示语
 */

export default {
  props: {
    /**
     * 提示语
     */
    titleWord: {
      type: String,
      default() {
        return this.$i18n.t("vue_label_SMS_nodata");
      },
    },
    /**
     * 缺省图 图片路径
     */
    imgSrc: {
      // 提示图片
      type: String,
      default: "icon-email4",
    },
  },
};
</script>

<style lang='scss' scoped>
.div_temporarily_no_data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #888888;
  .img {
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 15px;
  }
}
</style>