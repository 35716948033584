<template>
  <div :class="[showBorder ? 'active' : '']">
    <!-- 导出至MailChimp -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <div class="dialogBody">
        <div class="choseView" v-show="step === 1">
          <!-- title -->
          <div class="title">{{ $t("vue_label_dashboard_selectview") }}</div>
          <!-- 选择器 -->
          <el-select v-model="selectedView" placeholder="">
            <el-option
              style="max-width: 300px"
              v-for="item in viewOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- intro -->
          <div class="intro">
            <span>*</span>
            <!-- 导出至Mailchimp功能支持单次导出2000条记录 -->
            <div>{{ $t("front-electronic-export-intro") }}</div>
          </div>
        </div>
        <div class="choseList" v-show="step === 2">
          <div class="viewTitle">
            {{
              $t("front-electronic-export-listCount", { totalView: totalView })
            }}
            <!-- 当前视图总计{{ totalView }}条数据，选择导入的数据 -->
          </div>
          <!-- 选择器 -->
          <el-select v-model="total" placeholder="">
            <el-option
              style="max-width: 300px"
              v-for="item in choseOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <!-- Mailchimp列表 -->
          <div class="listTitle">
            {{ $t("front-electronic-export-listTitle") }}
          </div>
          <!-- 选择器 -->
          <el-select v-model="selectedChimp" placeholder="">
            <el-option
              style="max-width: 300px"
              v-for="item in chimpOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="intro">
            <span>*</span>
            <!-- 选择的数据将会导出至您选中的MailChimp列表中 -->
            <div>{{ $t("front-electronic-export-exportIntro") }}</div>
          </div>
        </div>
        <div class="exportSuccess" v-show="step === 3">
          <!-- logo -->
          <div class="logo">
            <svg aria-hidden="true" style="width: 90px; height: 90px">
              <use href="#icon-yishenpi"></use>
            </svg>
          </div>
          <div class="intro">
            <!-- 导出成功，请您再Mailchimp列表中查看 -->
            {{ $t("front-electronic-export-success") }}
          </div>
          <div class="btnGroup">
            <el-button v-show="goOnExport" @click="nextStep('goBack')">{{
              $t("front-electronic-export-buttonExport")
            }}</el-button>
            <el-button @click="nextStep('none')">{{
              $t("label.emailtocloudcc.button.complete")
            }}</el-button>
            <el-button type="primary" @click="nextStep('jump')">{{
              $t("front-electronic-export-goResult")
            }}</el-button>
          </div>
        </div>
        <div class="exportSuccess" v-show="step === 4">
          <div class="logo">
            <svg aria-hidden="true" style="width: 90px; height: 90px">
              <use href="#icon-jujue"></use>
            </svg>
          </div>
          <div class="intro">
            {{ $t("label.integrations.export.failed.Mailchimp") }}
          </div>
          <div class="btnGroup">
            <el-button @click="nextStep('goBack')">{{
              $t("label.import.page1.laststep")
            }}</el-button>
            <el-button type="primary" @click="nextStep('none')">{{
              $t("label.tabpage.closez")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <span slot="footer" v-if="step === 1 || step === 2">
        <el-button
          v-show="step === 1"
          type="primary"
          @click="nextStep('add')"
          >{{ $t("label.import.page1.nextstep") }}</el-button
        >
        <div v-show="step === 2">
          <el-button @click="nextStep('reduce')">{{
            $t("label.import.page1.laststep")
          }}</el-button>
          <el-button
            :loading="exprtLoading"
            type="primary"
            @click="nextStep('export')"
            >{{ $t("label.reportexport") }}</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as mailChimp from "./api.js";
import * as SetViewApi from "./api.js";

export default {
  data() {
    return {
      dialogVisible: false,
      showBorder: false,
      dialogTitle: this.$i18n.t("label.integrations.mailchimp.export"),
      step: 1,
      selectedView: "",
      selectedChimp: "",
      total: "",
      viewOptions: [],
      chimpOptions: [],
      choseOption: [],
      totalView: 0,
      errInfo: "",
      mailUrl: "",
      goOnExport: true,
      exprtLoading: false,
    };
  },
  methods: {
    // 导入MailChimp
    exportList() {
      let obj = {
        prefix: this.$route.params.prefix,
        viewId: this.selectedView,
        listId: this.selectedChimp,
        num: this.total,
      };
      mailChimp.GetMailchimpExport(obj).then((res) => {
        this.exprtLoading = false;
        if (res.returnCode === "1") {
          this.step = 3;
          this.choseOption.map((item) => {
            if (item.value === this.total) {
              item.disabled = true;
            }
          });
          let count = 0;
          this.choseOption.map((item) => {
            if (item.disabled) {
              count++;
            }
          });
          if (count === this.choseOption.length) {
            this.goOnExport = false;
          } else {
            this.goOnExport = true;
          }
          this.mailUrl = res.data.url;
        } else {
          this.step = 4;
          this.errInfo = res.returnInfo;
        }
      });
    },
    // 获取视图数据条数
    getViewTotal() {
      mailChimp
        .getAccountNum({
          prefix: this.$route.params.prefix,
          viewId: this.selectedView,
        })
        .then((res) => {
          this.choseOption = [];
          this.totalView = res.data.num;
          // 初始化选择数据条数列表
          this.totalView = Number(this.totalView);
          if (this.totalView === 0) {
            this.choseOption = [];
            this.$message.warning(
              this.$i18n.t("front-electronic-export-reChoseChimp")
            );
            this.step = 1;
          } else if (this.totalView <= 2000) {
            this.choseOption.push({
              label: `1-${this.totalView}`,
              value: 1,
              disabled: false,
            });
            this.total = this.choseOption[0].value;
          } else {
            let num = Math.floor(this.totalView / 2000);
            for (let index = 0; index < num; index++) {
              this.choseOption.push({
                label: `${2000 * index + 1}-${2000 * (index + 1)}`,
                value: 2000 * index + 1,
                disabled: false,
              });
            }
            this.choseOption.push({
              label: `${2000 * num + 1}-${this.totalView}`,
              value: 2000 * num + 1,
              disabled: false,
            });
            this.total = this.choseOption[0].value;
          }
        });
    },
    // 获取视图列表
    getViewList() {
      this.viewOptions = [];
      SetViewApi.GetViewList({ objId: this.$route.params.objId }).then(
        (res) => {
          this.viewOptions = res.data;
          this.selectedView = res.data[0].id;
        }
      );
    },
    // 获取MailChimp列表
    getMailChimpList() {
      mailChimp.GetMailchimpGetList().then((res) => {
        this.chimpOptions = res.data;
        this.selectedChimp = res.data[0].id;
      });
    },
    nextStep(type) {
      if (type === "add") {
        if (this.step === 1) {
          // 如果没有选择视图
          if (this.selectedView === "") {
            this.$message.warning(
              this.$i18n.t("vue_label_notice_pleaseselecview")
            );
            return;
          }
        }
        this.step += 1;
        if (this.step == 2) {
          this.total = "";
          this.selectedChimp = "";
          this.exprtLoading = false;
          this.getMailChimpList();
          this.getViewTotal();
        }
      } else if (type === "reduce") {
        this.step -= 1;
      } else if (type === "none") {
        this.dialogVisible = !this.dialogVisible;
      } else if (type === "export") {
        if (this.step == 2) {
          if (this.total === "") {
            this.$message.warning(
              this.$i18n.t("front-electronic-export-choseCount")
            );
            return;
          }
          if (this.selectedChimp === "") {
            this.$$message.warning(
              this.$i18n.t("front-electronic-export-choseChimp")
            );
            return;
          }
        }
        this.exprtLoading = true;
        this.exportList();
      } else if (type === "jump") {
        window.open(this.mailUrl);
      } else if (type === "goBack") {
        this.step = 2;
        this.total = "";
      }
    },
    open() {
      this.dialogVisible = !this.dialogVisible;
    },
    handleClose() {
      this.dialogVisible = !this.dialogVisible;
    },
  },
  watch: {
    step(val) {
      if (val >= 3) {
        this.showBorder = true;
        this.dialogTitle = "";
      } else {
        this.showBorder = false;
        this.dialogTitle = this.$i18n.t("label.integrations.mailchimp.export");
      }
    },
    dialogVisible(val) {
      if (val) {
        this.step = 1;
        this.getViewList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-select {
  width: 300px;
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.active {
  ::v-deep .el-dialog__header {
    border: none;
  }
  ::v-deep .el-dialog__footer {
    border: none;
  }
  .dialogBody {
    height: 270px !important;
  }
}
::v-deep .el-dialog__body {
  padding: 36px;
}
.dialogBody {
  height: 188px;
  .choseView {
    .title {
      margin-bottom: 12px;
      font-size: 14px;
      color: #333333;
    }
    .intro {
      margin-top: 28px;
      display: flex;
      span {
        color: #e5716a;
        margin-right: 5px;
      }
    }
  }
  .choseList {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    .viewTitle {
      margin-bottom: 10px;
    }
    .listTitle {
      margin: 10px 0px;
    }
    .intro {
      margin-top: 28px;
      display: flex;
      span {
        color: #e5716a;
        margin-right: 5px;
      }
    }
  }
  .exportSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    .intro {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin: 32px 0px;
    }
    ::v-deep .el-button {
      padding: 8px 20px;
    }
  }
}
</style>
