import { render, staticRenderFns } from "./kanban.vue?vue&type=template&id=186bb783&scoped=true&"
import script from "./kanban.vue?vue&type=script&lang=js&"
export * from "./kanban.vue?vue&type=script&lang=js&"
import style0 from "./kanban.vue?vue&type=style&index=0&id=186bb783&prod&lang=scss&scoped=true&"
import style1 from "./kanban.vue?vue&type=style&index=1&id=186bb783&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186bb783",
  null
  
)

export default component.exports