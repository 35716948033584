<!--
  标准对象顶部图表弹框
-->
<template>
  <div>
    <el-card class="box-card">
      <!-- 顶部标题 -->
      <div slot="header" class="clearfix">
        <span>{{ $t("vue_label_commonobjects_view_chart") }}</span>
        <svg class="icon guanbi" aria-hidden="true" @click="gb">
          <use href="#icon-chahao"></use>
        </svg>
      </div>
      <!-- 下拉选择及操作按钮 -->
      <div class="select_butt">
        <el-select
          :placeholder="$t('label_tabpage_pleaseSelectz')"
          v-model="selectChart"
          filterable
          @change="getViewChartDisplays"
        >
          <el-option
            v-for="(item, index) in chart"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 操作按钮 -->
        <div class="el-dropdown-link shezhi" v-popover:popoverEchart>
          <svg
            class="downXiala"
            aria-hidden="true"
            slot="reference"
            v-if="!shezhi"
            @mouseover="shezhi = true"
            @click="shezhi = true"
          >
            <use href="#icon-shezhi_02"></use>
          </svg>
          <svg
            class="downXiala"
            aria-hidden="true"
            slot="reference"
            v-else
            @mouseleave="shezhi = false"
          >
            <use href="#icon-shezhi_02_blue"></use>
          </svg>
        </div>
      </div>
      <!-- 图表显示及统计 -->
      <div class="chart_infos">
        <!-- 图表显示 -->
        <div class="content_chart">
          <div class="all_charts" v-show="isShows">
            <!-- 图表，包含漏斗 -->
            <chartsComponent
              :dashboardtype="showChartInfos.dashboardtype"
              :groupOneArr="showChartInfos.groupOneArr"
              :valueDataArr="showChartInfos.valueDataArr"
              :dataArr="showChartInfos.dataArr"
              :mapGroupOneFieldLabelName="
                showChartInfos.mapGroupOneFieldLabelName
              "
              :mapSummaryFieldLabelName="
                showChartInfos.mapSummaryFieldLabelName
              "
            />
          </div>
          <!-- 无图表时显示默认图片 -->
          <div v-show="!isShows" class="default_img">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-charts"></use>
            </svg>
            <!-- 请添加图表 -->
            <span @click="handleCommand('a')">{{
              $t("vue_label_commonobjects_view_chart_notice_pleaseaddchart")
            }}</span>
          </div>
        </div>
        <!-- 统计标题 -->
        <div
          class="bottom_statistical"
          @click="clickStatistical"
          v-show="isShows"
        >
          <i :class="icons" class="xiala"></i
          ><span>{{ $t("vue_label_report_statistics") }}</span>
        </div>
        <!-- 统计信息 -->
        <div
          class="statistical_infos"
          v-if="icons === 'el-icon-arrow-down' && isShows"
        >
          <div class="each_info" v-for="(item, index) in list" :key="index">
            <div>{{ item.group1 }}</div>
            <div>{{ item.statisticvalue }}</div>
          </div>
        </div>
      </div>
      <!-- 仪表板 -->
      <div class="dashboard_infos">
        <div class="dashboard_infos_content">
          <div class="div_header">
            <span>{{ $t("label.dashboard") }}</span>
            <span @click="jumpDashboardList">{{
              $t("vue_label_commonobjects_view_chart_gotodashboard")
            }}</span>
          </div>
          <!-- 仪表板列表 -->
          <div class="div_content" v-loading="loadingDashboard">
            <!-- 视图中有仪表板 -->
            <ul v-if="listDashboard.length > 0">
              <li
                v-for="(item, index) in listDashboard"
                :key="index"
                @click="jumpDashboard(item)"
              >
                <span>{{ item.name }}</span>
              </li>
            </ul>
            <!-- 没有仪表板 无相关仪表板-->
            <div class="noData_2" v-else>
              <temporarily-no-data
                :titleWord="$t('vue_no_related_dashboards')"
              />
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 新建弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      class="new_dialog"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="105px"
      >
        <el-form-item
          :label="$t('vue_label_commonobjects_view_chart_chartname')"
          prop="name"
        >
          <el-input v-model="ruleForm.name" placeholder=""></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('vue_label_commonobjects_view_chart_displaymode')"
          prop="chartType"
        >
          <el-select v-model="ruleForm.chartType" filterable placeholder="">
            <el-option
              v-for="item in chartTypes"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 合计依据 -->
        <el-form-item
          :label="$t('vue_label_commonobjects_view_chart_countby')"
          prop="valueData"
        >
          <el-select
            v-model="ruleForm.valueData"
            filterable
            placeholder=""
            :no-data-text="$t('vue_label_combined_field')"
          >
            <el-option
              v-for="item in valueDatas"
              :key="item.id"
              :label="item.fieldlabel"
              :value="item.fieldid"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 合计方式 -->
        <el-form-item
          :label="$t('vue_label_commonobjects_view_chart_countmode')"
          prop="valueType"
        >
          <el-select v-model="ruleForm.valueType" filterable placeholder="">
            <el-option
              v-for="item in valueTypes"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 分组依据 -->
        <el-form-item
          :label="$t('vue_label_commonobjects_view_chart_groupby')"
          prop="groupField"
        >
          <el-select v-model="ruleForm.groupField" filterable placeholder="">
            <el-option
              v-for="item in groupFields"
              :key="item.id"
              :label="item.fieldlabel"
              :value="item.fieldid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-checkbox v-model="ruleForm.groupNum" style="margin-left: 44px">{{
          $t("vue_label_commonobjects_view_chart_upto5")
        }}</el-checkbox>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="quxiao">{{
          $t("label.cancel")
        }}</el-button>
        <el-button size="mini" type="primary" @click="submit('ruleForm')">{{
          $t("label.save")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 删除图表弹框 -->
    <ComdeleteAlert
      :type="$t('vue_label_commonobjects_view_chart')"
      :isShowDeleteAlert="isShowDeleteAlert"
      :name="ruleForm.name"
      @deleteAffirm="deleteViewChart"
      @deleteCancel="deleteCancel"
    />

    <!-- 图表操作弹框 -->
    <el-popover
      ref="popoverEchart"
      placement="bottom-end"
      trigger="click"
      popper-class="popper_echart"
    >
      <div class="operation_style">
        <!-- 显示为 -->
        <div class="top_title">
          {{ $t("vue_label_commonobjects_view_chart_displayas") }}
        </div>
        <ul>
          <!-- 需要先选择一个图表，再对图表进行切换类型操作 -->
          <li v-for="(item, index) in chartTypes" :key="index">
            <el-button
              type="text"
              :disabled="!selectChart"
              @click="changeChartType(item)"
            >
              {{ item.value }}
            </el-button>
          </li>
        </ul>
        <ul>
          <li
            v-for="(item, index) in operationTypes"
            :key="index"
            @click="handleCommand(item.id)"
          >
            <!-- 视图没有编辑权限，则图表不能新建、编辑、删除 -->
            <el-button type="text" :disabled="ismodifyFalg === 'false'">
              {{ item.value }}
            </el-button>
          </li>
        </ul>
      </div>
    </el-popover>
  </div>
</template>

<script>
import {
  getViewChartList,
  getViewChartSave,
  getViewChartNew,
  getViewChartEdit,
  getViewChartDisplay,
  deleteViewChart,
} from "./api";
import ComdeleteAlert from "@/views/dashboardObject/dashboardIndex/components/ComdeleteAlert.vue"; // 删除图表弹框
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
import chartsComponent from "@/components/Chart/chartsComponent.vue"; // 图表

export default {
  props: {
    viewId: {
      // 视图id
      type: String,
      default: "",
    },
    ismodifyFalg: {
      // 最近查看、最近创建、最近修改这三个视图特殊，不能查看图表；其他视图均可查看图表
      // 视图是否有编辑权限，决定了图表是否有新建、编辑、删除权限
      type: String,
      default: "true",
    },
    listDashboard: {
      // 对象下的仪表板列表
      type: Array,
      default: () => [],
    },
    loadingDashboard: {
      // 仪表板列表loading
      type: Boolean,
      default: false,
    },
  },
  components: {
    ComdeleteAlert,
    TemporarilyNoData,
    chartsComponent,
  },
  created() {
    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);
  },
  data() {
    return {
      isShows: false, // 是否显示图表
      icons: "el-icon-arrow-right",
      shezhi: false,
      chart: [], // 图表列表
      title: this.$i18n.t("vue_label_commonobjects_view_chart_newchart"), //新建图表
      dialogVisible: false, //新建页面显示与隐藏
      ruleForm: {
        chartId: "", // 图表id
        name: "", // 图表名称
        chartType: "", // 展开方式
        valueData: "", // 统计字段（合计依据）
        valueType: "", // 统计类型（合计方式）
        groupField: "", // 分组字段（分组依据）
        groupNum: true, // 最多展示5个分组，默认选中
      },
      selectChart: "", // 下拉选择框中选择的图表
      valueDatas: [], // 统计字段集合
      valueTypes: [
        {
          label: this.$i18n.t("label_sum_up"), //'求和'
          value: "sum",
        },
        {
          label: this.$i18n.t("label_average_value"), //'平均值'
          value: "avg",
        },
      ], // 统计字段对应的类型集合
      groupFields: [], // 分组字段集合
      list: [], // 分组及对应的统计值数据
      displaytype: "", //图表类型
      // 展示图表所需的数据
      showChartInfos: {
        dashboardtype: "", // 图表类型
        groupOneArr: [], // 一级分组数据
        valueDataArr: [], // 统计数据
        dataArr: [], // 饼图、漏斗图series数据
        mapGroupOneFieldLabelName: "", // 一级分组字段label
        mapSummaryFieldLabelName: "", // 统计字段label
      },
      isSaveChartType: true, // 是否保存图表类型，在切换图表的时候不需要保存
      chartTypes: [
        {
          id: "column_0",
          value: this.$i18n.t("vue_label_commonobjects_view_chart_verticalbar"), //垂直柱状图
        },
        {
          id: "bar_0",
          value: this.$i18n.t(
            "vue_label_commonobjects_view_chart_horizontalbar"
          ), //水平柱状图
        },
        {
          id: "pie",
          value: this.$i18n.t("vue_label_commonobjects_view_chart_pie"), //饼图
        },
        {
          id: "funnel",
          value: this.$i18n.t("vue_label_commonobjects_view_chart_funnel"), //漏斗图
        },
      ], //类型选择框
      operationTypes: [
        {
          id: "a",
          value: this.$i18n.t("vue_label_commonobjects_view_chart_newchart"), //新建图表
        },
        {
          id: "b",
          value: this.$i18n.t("vue_label_commonobjects_view_chart_editchart"), //编辑图表
        },
        {
          id: "c",
          value: this.$i18n.t("vue_label_commonobjects_view_chart_deletechart"), //删除图表
        },
      ], // 图表操作类型
      permission: {},
      isShowDeleteAlert: false, // 删除图表弹框是否显示
      //新建图表检验
      rules: {
        name: [
          // vue.please.enter.a.chart.name 请输入图表名称
          {
            required: true,
            message: this.$i18n.t("vue.please.enter.a.chart.name"),
            trigger: "blur",
          },
        ],
        //vue.please.select.the.expansion.method 请选择展开方式
        chartType: [
          {
            required: true,
            message: this.$i18n.t("vue.please.select.the.expansion.method"),
            trigger: "blur",
          },
        ],
        valueData: [
          //vue.please.select.the.aggregate.basis 请选择合计依据
          {
            required: true,
            message: this.$i18n.t("vue.please.select.the.aggregate.basis"),
            trigger: "blur",
          },
        ],
        valueType: [
          //vue.please.select.the.total.method 请选择合计方式
          {
            required: true,
            message: this.$i18n.t("vue.please.select.the.total.method"),
            trigger: "blur",
          },
        ],
        groupField: [
          //vue.please.select.grouping.basis 请选择分组依据
          {
            required: true,
            message: this.$i18n.t("vue.please.select.grouping.basis"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    // 监听图表新建编辑弹框是否关闭，清空校验
    dialogVisible: {
      handler() {
        if (!this.dialogVisible) {
          this.$refs.ruleForm.resetFields();
        }
      },
    },
  },
  mounted() {
    this.getViewChartListss();
  },
  methods: {
    // 获取视图内所有图表列表
    async getViewChartListss() {
      this.chart = [];
      this.isShows = false;
      let params = {
        viewId: this.viewId, // 视图Id
      };
      let res = await getViewChartList(params);
      if (res.result) {
        this.permission = res.data.permission;
        if (this.permission.isView === false) {
          this.$message.info(this.$i18n.t("c169"));
          return;
        } else {
          if (res.data.charts.length === 0) {
            this.selectChart = "";
          } else {
            this.isShows = true;
            this.chart = res.data.charts;
            if (
              !this.selectChart ||
              this.chart.every((item) => item.id !== this.selectChart)
            ) {
              // 下拉框中未选择图表或者已选择的图表已被删除
              this.selectChart = res.data.charts[0].id; // 默认选中第一个图表
              this.getViewChartDisplays(this.selectChart);
            }
          }
        }
      }
    },
    // 对图表进行操作：新建、编辑、删除
    async handleCommand(type) {
      this.$refs.popoverEchart.doClose(); // 手动控制操作气泡关闭
      if (this.ismodifyFalg === "false") {
        // 若是没有视图编辑权限则不可进行以下操作
        return;
      }
      switch (type) {
        case "a": // 新建
          this.title = this.$i18n.t(
            "vue_label_commonobjects_view_chart_newchart"
          ); //新建图表
          this.dialogVisible = true;
          this.ruleForm.chartId = "";
          this.getViewChartNews();
          break;
        case "b": // 编辑
          this.title = this.$i18n.t(
            "vue_label_commonobjects_view_chart_editchart"
          ); //编辑图表
          this.ruleForm.chartId = this.selectChart;
          this.getViewChartEdit();
          if (this.isShows) {
            this.dialogVisible = true;
          }
          break;
        case "c": // 删除
          this.ruleForm.chartId = this.selectChart;
          await this.getViewChartEdit();
          if (this.isShows) {
            this.isShowDeleteAlert = true;
          }
          break;
      }
    },
    // 获取新增时的图表信息
    async getViewChartNews() {
      this.ruleForm = {
        chartId: "",
        name: "",
        chartType: this.chartTypes[0].id,
        valueData: "",
        valueType: this.valueTypes[0].value,
        groupField: "",
        groupNum: true,
      };
      let params = {
        viewId: this.viewId, // 视图Id
      };
      let {
        data: { groupFieldList },
        data: { statisticFieldList },
      } = await getViewChartNew(params);
      this.groupFields = groupFieldList;
      this.valueDatas = statisticFieldList;
      this.ruleForm.valueData =
        this.valueDatas.length > 0 ? this.valueDatas[0].fieldid : "";
      this.ruleForm.groupField =
        this.groupFields.length > 0 ? this.groupFields[0].fieldid : "";
    },
    // 获取编辑时的图表信息
    async getViewChartEdit() {
      if (!this.ruleForm.chartId) {
        // 下拉框中未选择图表，此时不可编辑
        this.$message.info(this.$i18n.t("c168"));
        return;
      }
      let params = {
        id: this.ruleForm.chartId, // 图表Id
      };
      let {
        data: { groupFieldList },
        data: { statisticFieldList },
        data: { viewChart },
      } = await getViewChartEdit(params);
      this.groupFields = groupFieldList;
      this.valueDatas = statisticFieldList;
      this.ruleForm = {
        chartId: viewChart.id,
        name: viewChart.name,
        chartType: viewChart.displaytype,
        valueData: viewChart.statisticfield,
        valueType: viewChart.statistictype,
        groupField: viewChart.groupfield1,
        groupNum: viewChart.display5 ? JSON.parse(viewChart.display5) : false,
      };
    },
    // 切换图表类型
    async changeChartType(item) {
      this.isSaveChartType = false;
      this.$refs.popoverEchart.doClose(); // 手动控制操作气泡关闭
      this.displaytype = item.id; // 切换的图表类型
      await this.getViewChartDisplays(this.selectChart);
      this.isSaveChartType = true;
    },
    // 保存图表：新建、编辑
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          /*--
            id	否	string	视图图表id，编辑保存时必填
            name	是	string	图表名称
            viewid	是	string	视图id
            displaytype	是	string	图表类型
            statisticfield	是	string	统计字段id
            statistictype	是	string	统计类型
            groupfield1	是	string	分组字段1
            display5	否	string	是否只显示前5条(是的话值为true ) */
          let params = {
            id: this.ruleForm.chartId,
            name: this.ruleForm.name,
            viewid: this.viewId,
            displaytype: this.ruleForm.chartType,
            statisticfield: this.ruleForm.valueData,
            statistictype: this.ruleForm.valueType,
            groupfield1: this.ruleForm.groupField,
            display5: this.ruleForm.groupNum,
          };
          let res = await getViewChartSave(params);
          if (res.result) {
            this.dialogVisible = false;
            this.selectChart = res.data;
            this.getViewChartDisplays(this.selectChart); //获取图表数据
            this.getViewChartListss();
          }
        } else {
          return false;
        }
      });
    },
    // 删除图表
    async deleteViewChart() {
      let params = {
        id: this.ruleForm.chartId,
      };
      let { result } = await deleteViewChart(params);
      if (result) {
        this.$message.success(this.$i18n.t("c3"));
        this.isShowDeleteAlert = false;
        this.getViewChartListss();
      }
    },
    // 删除图表弹框隐藏
    deleteCancel() {
      this.isShowDeleteAlert = false;
    },
    // 获取图表数据
    async getViewChartDisplays(value) {
      let params = {
        id: value, // 图表Id
      };
      let res = await getViewChartDisplay(params);
      if (res.data) {
        // 如果后端返回图表数据是null或空串，则需转成'-'
        res.data.values.map((item) => {
          if (item.group1 === null || item.group1 === "") {
            this.$set(item, "group1", "-");
          }
        });
        this.list = res.data.values; // 统计信息
        if (this.isSaveChartType) {
          // 需要保存图表类型，则采用接口返回的类型即可
          this.displaytype = res.data.viewChart.displaytype; // 选择的图表类型
        }
        this.getOptionDatas(
          res.data.values,
          res.data.statisticField,
          res.data.group1label
        );
      }
    },
    // 根据接口返回数据获取对应的图表options
    getOptionDatas(values, statisticField, group1label) {
      let groupOneArr = [];
      let valueDataArr = [];
      let seriesData = [];
      if(values?.length>0){
        values.map((item) => {
          // 接口没有返回字段参数，固定取第二个数据
          let key = Object.keys(item);
          let keyname=key[1];
          groupOneArr.push(item[keyname]);
          valueDataArr.push(item.statisticvalue);
          seriesData.push({
            value: item.statisticvalue,
            name: item[keyname],
          });
        });
      }
      this.showChartInfos = {
        dashboardtype: this.displaytype,
        groupOneArr: groupOneArr,
        valueDataArr: valueDataArr,
        dataArr: seriesData,
        mapGroupOneFieldLabelName: group1label,
        mapSummaryFieldLabelName: statisticField.fieldlabel,
      };
    },
    // 点击统计信息：展开、收起
    clickStatistical() {
      if (this.icons === "el-icon-arrow-down") {
        // 展开状态
        this.icons = "el-icon-arrow-right";
      } else {
        this.icons = "el-icon-arrow-down";
      }
    },
    // 隐藏新建编辑图表弹框
    quxiao() {
      this.dialogVisible = false;
    },
    // 关闭图表弹框
    gb() {
      this.$parent.chartsBoard();
    },
    // 点击跳转至仪表板列表
    jumpDashboardList() {
      this.$router.push({
        path: "/dashboardObject/dashBoardList",
      });
    },
    // 点击跳转至单个仪表板
    jumpDashboard(item) {
      this.$router.push({
        path: `/dashboardObject/dashboardIndex/${item.id}`,
        query: {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .box-card {
  height: 100%;
  position: relative;
  box-shadow: -6px 1px 11px -5px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0;
  ::v-deep .el-card__header {
    padding: 11px 20px;
    border-bottom: 1px solid #dcdcdc;
    font-size: 12px;
    color: #3e3e3c;
  }
  .clearfix {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: bold;
    }
  }
  .clearfix::before,
  .clearfix::after {
    display: none !important;
  }

  .el-card__body {
    padding: 0 !important;
    height: calc(100% - 47px);
    overflow: auto;
  }
  .guanbi {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
::v-deep .select_butt {
  margin: 11px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-select {
    width: 218px;
    .el-input__inner {
      border-radius: 3px;
    }
  }
  .el-input__suffix {
    .el-input__icon {
      // line-height: 0;
    }
  }
  .shezhi {
    display: block;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.chart_infos {
  margin: 11px 22px;

  .content_chart {
    height: 230px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    padding: 16px;
    .all_charts {
      height: 100%;
    }
    .default_img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img,
      svg {
        display: block;
        margin: 0px auto;
        width: 100px;
        height: 50px;
        margin-right: 50px;
      }
      span {
        display: block;
        margin-top: 20px;
        font-size: 12px;
        color: #3e3e3c;
        cursor: pointer;
      }
    }
  }
  .bottom_statistical {
    font-size: 12px;
    cursor: pointer;
    i {
      width: 5px;
      height: 10px;
      cursor: pointer;
      padding-top: 10px;
      margin-right: 15px;
    }
    span {
      margin-bottom: 20px;
    }
  }
  .statistical_infos {
    padding: 0 20px;
    max-height: 100px;
    font-size: 12px;
    overflow-y: auto;
    .each_info {
      display: flex;
      justify-content: space-between;
    }
  }
}
.dashboard_infos {
  border-top: 1px dashed #dcdcdc;
  .dashboard_infos_content {
    margin: 11px 22px;
    height: 172px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    .div_header {
      padding: 8px 12px;
      margin: 0 !important;
      font-size: 12px;
      background: #006dcc;
      color: #fff;
      display: flex;
      justify-content: space-between;
      span:last-child {
        cursor: pointer;
      }
    }
    .div_content {
      height: calc(100% - 36px);
      ul {
        overflow-y: auto;
        height: 100%;
        li {
          padding: 4px 12px;
          font-size: 12px;
          color: #006dcc;
          cursor: pointer;
          span {
            border-bottom: 1px solid #006dcc;
          }
        }
      }
      .noData_2 {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 10px;
        color: #666666;
        img {
          width: 132px;
          height: 77px;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  .el-input__inner {
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
  .el-input__suffix {
    top: 0;
  }
}
</style>

<style lang="scss">
.el-popover.popper_echart {
  min-width: 120px !important;
  padding: 0 !important;
  .operation_style {
    background: #f3f2f2;
    color: #333333;
    .top_title {
      padding: 16px 0 12px 13px;
    }
    ul:last-child {
      margin-bottom: 0 !important;
    }
    ul {
      li {
        padding: 10px 13px;
        font-size: 12px;
        background: #fff;
        border-bottom: 1px solid #dddbda;
        cursor: pointer;
        .el-button {
          padding: 0;
          font-size: 12px;
          color: #333333;
          &:hover {
            color: #006dcc;
          }
        }
        .el-button.is-disabled {
          color: #c0c4cc;
        }
        &:hover {
          color: #006dcc;
        }
      }
      li:first-child {
        border-top: 1px solid #dddbda;
      }
    }
  }
}
</style>
