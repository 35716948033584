import * as CommonObjApi from "./api";
import detailSendCard from "@/utils/robotMessage.js";

export default {
  data() {
    return {
      // 用户类型:用户、队列、伙伴云用户
      ownerTypeList: [],
      ownerRaletionList: [], // 可选的其余一起更改所有人的集合
      // 初始化其余一起更改所有人的集合
      optional: [
        {
          // 转移其他人所有未处理业务机会
          label: this.$i18n.t("vue_label_account_outstanding_business"),
          value: "otherUntreatedOpportunity",
        },
        {
          // 转移客户所有人的已结束业务机会
          label: this.$i18n.t("vue_label_account_business"),
          value: "processedOpportunity",
        },
        {
          // 转移客户所有人的所有合同
          label: this.$i18n.t("vue_label_account_all_contracts"),
          value: "ffe2011000049909E5PO",
        },
        {
          // 转移客户所有人的全部个案
          label: this.$i18n.t("vue_label_account_cases"),
          value: "ffe201100005108mj4GB",
        },
        {
          // 转移客户所有人的全部发票
          label: this.$i18n.t("vue_label_account_invoices"),
          value: "ffe20181108invoice04",
        },
        {
          // 转移客户所有人的全部报价单
          label: this.$i18n.t("vue_label_account_quotations"),
          value: "ffe201100006202iYyrO",
        },
      ],
      // 客户时默认展示同步修改的记录
      transferClientDeault: [
        { name: this.$i18n.t("vue_label_account_unprocessed_oppprtunities") },// 未处理业务机会
        { name: this.$i18n.t("label.contact") },// 联系人
        { name: this.$i18n.t("page.un.activities") },// 未处理活动
        { name: this.$i18n.t("vue_label_account_collection_plan") },// 未处理的收款计划
      ],
      transferDeault: []
    }
  },
  methods: {
    /**
     * 页面初始化方法
     * @param {记录所属对象id} objId 
     * @param {更改所有人的记录id} ids 
     */
    initTransferOwner(objId, ids) {
      this.transferDeault = []
      this.ownerRaletionList = []

      if (objId === 'account') {
        this.getTransferDeault(objId)
        this.getOwnerRelations(ids)
      }
      this.checkQueueAndPartner(objId)
    },
    /**
     * 获取默认转移的相关列表
     * @param {记录所属对象id} objId 
     */
    getTransferDeault(objId) {
      // 记录所属对象为客户时,展示默认转移的相关列表
      this.transferDeault = this.transferClientDeault
    },
    /**
     * 更改所有人是否展示伙伴云和队列
     * @param {记录所属对象id} objId 
     */
    checkQueueAndPartner(objId) {
      this.ownerTypeList = [{
        value: "user",
        // 用户
        label: this.$i18n.t("label.user"),
      }]
      CommonObjApi.checkQueueAndPartner({ id: objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          if (res.data.isQueue) {
            this.ownerTypeList.push({
              value: "queue",
              // 队列
              label: this.$i18n.t("label.queue"),
            })
          }
          if (res.data.isPartner) {
            this.ownerTypeList.push({
              value: "partneruser",
              label: this.$i18n.t("label.partneruser"),//合作伙伴云用户
            })
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    /**
     * 获取更改所有人页面信息
     * @param {更改所有人的记录id} ids 
     */
    getOwnerRelations(ids) {
      this.ownerRaletionList = JSON.parse(JSON.stringify(this.optional));
      CommonObjApi.getTransfer({ recordIds: ids }).then((res) => {
        res.data.relatedlist &&
          res.data.relatedlist.length > 0 &&
          res.data.relatedlist.forEach((item) => {
            this.ownerRaletionList.push({
              label: item.label,
              value: item.fieldId,
            });
          });
      });
    },
    /**
     * 更改记录所有人
     * @param {是否发送邮件} isSendMail 
     * @param {记录id集合} recordId 
     * @param {新的所有人id} newOwnerId 
     * @param {同步修改的相关列表集合} relatedIds 
     */
    saveTransferOwner(isSendMail, recordId, newOwnerId, relatedIds) {
      if (recordId.indexOf(",") === -1) {
        let params = {
          isSendMail: isSendMail,
          recordId: recordId,
          newOwnerId: newOwnerId,
          relatedIds: relatedIds,
          recordName: "",
          isActivity: true,
        };
        CommonObjApi.updateChangeOwner(params).then((res) => {
          if (res.result && res.returnCode === "1") {
            let self = this;
            //群策卡片
            if (res.data && res.data.cardList) {
              for (let i = 0; i < res.data.cardList.length; i++) {
                (function (i) {
                  setTimeout(async () => {
                    if (
                      res.data.cardList[i].fieldsList[0].oldField !=
                      res.data.cardList[i].fieldsList[0].newField
                    ) {
                      detailSendCard(res.data.cardList[i].groupId, {
                        title: self.$i18n.t(
                          "vue_label_commonobjects_detail_record_changes"
                        ),
                        recordId: res.data.cardList[i].id,
                        type: "TRACK_CARD",
                        body: res.data.cardList[i].fieldsList,
                        changer: localStorage.getItem("username"),
                      });
                    }
                  }, 500 * i);
                })(i);
              }
            }

            let errorMsg = "";
            if (
              res.data &&
              res.data.wqxlist !== null &&
              res.data.wqxlist !== undefined
            ) {
              res.data.wqxlist.forEach((wqxData) => {
                errorMsg += this.$i18n.t('vue_label_notice_batchedit_fail', [
                  wqxData.id,
                  wqxData.batcherror,
                ])
              });
              this.$message.error(errorMsg);
              this.closeTransferOwnerDialog();
            } else {
              this.ownerId = "";
              this.ownerName = "";
              this.ownerType = "user";
              this.checkList = [];
              this.saveTransferOwnerSuccess();
              errorMsg === "" &&
                this.$message.success(
                  this.$i18n.t("vue_label_notice_change_success")
                );
            }
          } else {
            // 更改失败  vue_label_notice_change_fail
            this.$message.error(this.$i18n.t("vue_label_notice_change_fail"));
          }
        }).catch(()=>{});
      } else {
        // 批量转移所有人
        let params = {
          isSendMail: isSendMail,
          recordIds: recordId,
          newOwnerId: newOwnerId,
          relatedIds: relatedIds,
        };
        CommonObjApi.updateTransfer(params).then((res) => {
          if (res.result && res.returnCode === "1") {
            let self = this;
            //群策卡片
            if (res.data.cardList) {
              for (let i = 0; i < res.data.cardList.length; i++) {
                (function (i) {
                  setTimeout(async () => {
                    if (
                      res.data.cardList[i].fieldsList[0].oldField !=
                      res.data.cardList[i].fieldsList[0].newField
                    ) {
                      detailSendCard(res.data.cardList[i].groupId, {
                        title: self.$i18n.t(
                          "vue_label_commonobjects_detail_record_changes"
                        ),
                        recordId: res.data.cardList[i].id,
                        type: "TRACK_CARD",
                        body: res.data.cardList[i].fieldsList,
                        changer: localStorage.getItem("username"),
                      });
                    }
                  }, 500 * i);
                })(i);
              }
            }

            let errorMsg = "";
            if (
              res.data &&
              res.data.wqxlist !== null &&
              res.data.wqxlist !== undefined
            ) {
              res.data.wqxlist.forEach((wqxData) => {
                errorMsg += this.$i18n.t('vue_label_notice_batchedit_fail', [
                  wqxData.id,
                  wqxData.batcherror,
                ])
              });
              this.$message.error(errorMsg);
              this.closeTransferOwnerDialog();
            } else {
              this.ownerId = "";
              this.ownerName = "";
              this.ownerType = "user";
              this.checkList = [];
              this.saveTransferOwnerSuccess();
              errorMsg === "" &&
                this.$message.success(
                  this.$i18n.t("vue_label_notice_change_success")
                );
            }
          } else {
            // 更改失败  vue_label_notice_change_fail
            this.$message.error(this.$i18n.t("vue_label_notice_change_fail"));
          }
        });
      }
    },
  }
}
