import axios from '@/config/httpConfig'

// 获取MailChimp权限
export function GetMailchimpGetMailChimpPermission(data) {
    return axios.post('/mailchimp/getMailChimpPermission', data)
}

// 获取MailChimp列表
export function GetMailchimpGetList(data) {
    return axios.post('/mailchimp/getList', data)
}

// 导入MailChimp
export function GetMailchimpExport(data) {
    return axios.post('/mailchimp/export', data)
}

// 获取视图数据条数
export function getAccountNum(data) {
    return axios.post('/mailchimp/getAccountNum', data)
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}